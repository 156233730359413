import React from "react";
import Table from "react-bootstrap/Table";
import "./ReportTable.css";
// import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
// import Sidebar from "../../Layout/Sidebar/Sidebar";
import { API } from "../../../global";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import Header from "../../Layout/Navbar/Navbar";
import { useEffect } from "react";
import moment from "moment";

// import { Oval } from "react-loader-spinner";

function ReportTable() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchList, setSearchlist] = useState([]);

  const [runLogList, setRunLogList] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line
    getRunLog();
    // eslint-disable-next-line
  }, []);

  const getRunLog = async () => {
    await fetch(`${API}/tests/testsuites/report/runlog/${id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      .then((pro) => {
        console.log(pro.data);
        setRunLogList(pro.data);
        setSearchlist(pro.data)
      });
     

    //setLoading(false);
  };
  const getFilter =(value)=> {

    let addTstoIds = searchList.map((d)=>{
      return {...d, status :d.failed_test_cases.length>0 ? "Failed" : (d.skipped_test_cases.length===d.testcase_count)? "skipped" :(d.failed_test_cases.length===0 && d.passed_test_cases.length===0 && d.skipped_test_cases.length===0)?"Not Run" :(d.passed_test_cases.length===d.testcase_count)?"Passed" :"Failed"}
    });
  
    const lowercasedValue = value.toLowerCase().trim();
  
    if (lowercasedValue.length === 0){
      setRunLogList(searchList)
    } else {
      const filteredData = addTstoIds.filter(item => {
        return Object.keys(item).some(key =>
           item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      // let removeTstoIds = filteredData.map((d)=>{
      //   return {...d, test_case_id : d.test_case_id.replace('TC', '')}
      // });
      setRunLogList(filteredData);
    }
  }  

  return (
    <div className=" overflow-hidden">
      <Header testcaselist={getFilter}/>
     
     <div className="reportList_testsuite">
      <Container fluid>
        <Row className="report_test-row">
          <Col>
            <div className="d-flex first-head justify-content-start">
              Test Suite Execution Report List
            </div>
          </Col>
        </Row>
      </Container>
      {runLogList.length > 0 ? (
        <Table className="reportList-table">
          <thead
            style={{
              backgroundColor: "#E6C6E840",
              color: "#805382",
            }}
          >
            <tr>
              <th style={{ borderRight: "1px solid #805382", width: "16%" }}>
                Execution Time
              </th>
              <th style={{ borderRight: "1px solid #805382", width: "16%" }}>
                Environment
              </th>
              <th style={{ borderRight: "1px solid #805382", width: "16%" }}>
                Executed By
              </th>
              <th style={{ borderRight: "1px solid #805382", width: "16%" }}>
                Status
              </th>
              <th style={{ borderRight: "1px solid #805382", width: "16%" }}>
                Instant/Scheduled
              </th>
            </tr>
          </thead>
          <tbody style={{ textAlign: "center" }}>
            {runLogList.map((data, i) => (
              <tr style={{ height: "10px",cursor: "pointer" }}    
                  onClick={() => navigate(`/qe/testReport/${data._id}`)}>
                <td>{moment(data.updated_at).format("D MMM h:mm a")}</td>
                <td
              
                >
                  {data.environment}
                </td>
                <td>{data.updated_by.Ad_username}</td>
                <td
                  style={{
                    color:
                    data.failed_test_cases.length>0 ? "#DC3545" : (data.skipped_test_cases.length===data.testcase_count)? "#ff5f15" :(data.failed_test_cases.length===0 && data.passed_test_cases.length===0 && data.skipped_test_cases.length===0)?"#808080" :(data.passed_test_cases.length===data.testcase_count)?"#7EB965" :"#DC3545"
                    //   data.test_suite_id.last_run_status === "failed"
                    //     ? "#DC3545"
                    //     : "#7EB965",
                    // fontWeight: "bold",
                  }}
                >
                  {data.failed_test_cases.length>0 ? "Failed" : (data.skipped_test_cases.length===data.testcase_count)? "skipped" :(data.failed_test_cases.length===0 && data.passed_test_cases.length===0 && data.skipped_test_cases.length===0)?"Not Run" :(data.passed_test_cases.length===data.testcase_count)?"Passed" :"Failed"}
                </td>
                <td>Instant</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <label style={{ marginTop: "20%" }}>No Records Found</label>
      )}
    </div>
    </div>
  );
}

export default ReportTable;
