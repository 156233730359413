import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import edit from "../../../../assets/Icons/Edit.svg";
import Form from "react-bootstrap/Form";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import "./EditApplication.css";
import { API } from "../../../../global";
import Multiselect from "multiselect-react-dropdown";


const options = [
  {
    scroll: false,
    backdrop: false,
    placement: "end",
  },
];

function OffCanvasExample({ ...props }) {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow((s) => !s);
  const [showResults, setShowResults] = useState(false);
  const onClick = () => setShowResults((s) => !s);

  let editDataValue=props.editValue;


  const [tenant_id, setTenantId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [project_id, setProject_id] = useState("");
  // const [Editproject_id, setEditProject_id] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");
  const [projectName, setProjectName] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [checkTS, setCheckTS] = useState([]);
  // const [btnDisable, setBtnDisable] = useState(false);
  // const [editApp, setEditApp] = useState([]);
  const [stakeholderlist, setStakeholderlist] = useState([]);
  

  const handleClose = () => {
    setShow(false);
    setTenantId(props.editValue.tenant_id);
    setName(props.editValue.name);
    setDescription(props.editValue.description);
    setType(props.editValue.type);
    setCreatedBy(props.editValue.created_by);
    setUpdatedBy(props.editValue.updated_by);
    setFormErrors({})

  };

  // let arrList = [];
  let mailList = [];
  let finalArr = [];
  let ray = [];

   let proId = localStorage.getItem('project-id')
  useEffect(() => {
    getProject();

    getStakeholder(proId)
    setTenantId(props.editValue.tenant_id);
    setName(props.editValue.name);
    setDescription(props.editValue.description);
    setType(props.editValue.type);
    
    if(props.editValue.project_id !== undefined){
      setProject_id(props.editValue.project_id._id)
    }
    // if(finalArr.length !== 0){
    //   setCheckTS(finalArr)
    // }
      // setCheckTS(finalArr)
   
   
    // eslint-disable-next-line
    

    // eslint-disable-next-line
    // setProject_id(editDataValue.project_id);
    setCreatedBy(props.editValue.created_by);
    setUpdatedBy(props.editValue.updated_by);
    // eslint-disable-next-line
  }, [props.editValue]);


  // console.log(editDataValue.stakeholder,'datavalue');
  // if(editDataValue.stakeholder !== undefined){
  //   editDataValue.stakeholder.forEach((d, i) => {
  //     console.log(stakeholderlist,'stake');
  //     stakeholderlist.forEach((s, i) => {
  //       if (d === s._id) {
  //         finalArr.push(s)
  //       }
  //     })
  //   })
  // }
 
  // console.log(finalArr,'ddd');

 

  const getProject = async () => {
    await fetch(`${API}/admin/project`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      .then((pro) => {
        setProjectName(pro.data);
      })
    // const list = await appList.json();
    // setProjectName(list.data);
  };
  const getStakeholder =  (id) => {
    // setLoading(true)
    finalArr = []
     fetch(`${API}/admin/stakeholder/get/${id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      .then((pro) => {
        setStakeholderlist(pro.data)
        // console.log(editDataValue.stakeholder,'stake', pro.data);
        editDataValue.stakeholder.forEach((d, i) => {
          // console.log(stakeholderlist,'stake');
          pro.data.forEach((s, i) => {
            if (d === s._id) {
              // console.log(s, 's');
              finalArr.push(s)
              setCheckTS(finalArr);

            }
          })
        })
      })
      
      // .then(()=> setLoading(false))
  };


  const editApplication = () => {
    console.log(checkTS, 'aaa');
    // if (arrList.length !== 0) {

      finalArr = [];     // let stakemem = [...arrList, ...finalArr];
      mailList = [];
      ray = [];
      checkTS.forEach((ar, i) => {
        if (mailList[i] !== ar._id)
          mailList.push(ar._id);
      })
      ray = mailList.filter((l, i, a) => a.indexOf(l) === i);
    // }
    // else {
    //   mailList = [];
    //   ray = [];
    //   let stakemem = [...arrList, ...finalArr];
    //   // let ray = stakemem.filter((l, i, a) => a.indexOf(l) == i);
    //   stakemem.forEach((ar, i) => {
    //     if (mailList[i] !== ar._id)
    //       mailList.push(ar._id);
    //   })
    //   ray = mailList.filter((l, i, a) => a.indexOf(l) === i);
    // }
    
  const EditData = {
    tenant_id: tenant_id,
    name: name,
    description: description,
    type: type,
    stakeholder: ray,
    project_id: project_id,
    created_by: createdBy,
    updated_by: updatedBy,
  };
  const validate = () => {
    let errors = {};

    if (!EditData.name) {
      errors.name = "Cannot be blank";
    }
    if (!EditData.description) {
      errors.description = "Cannot be blank";
    } 
    if (!EditData.project_id) {
      errors.project_id = "Cannot be blank";
    } 
    if (!EditData.type) {
      errors.type = "Cannot be blank";
    } 
    // if (EditData.stakeholder.length === 0) {
    //   errors.stakeholder = "Cannot be blank";
    // } 
    return errors;
  };
  validate()
  
  if(Object.keys(validate()).length === 0){
 fetch(`${API}/admin/application/${props.editValue._id}`, {
      method: "PUT",
      body: JSON.stringify(EditData),
      headers: {
        "content-type": "application/json",

        authorization: `${localStorage.getItem("access-key")}`,
      },
    }).then(() => {
      handleClose();
      console.log(EditData,'edit');
      window.location.reload();
      // localStorage.removeItem("ts-edit");
    });
  }else{
    setFormErrors(validate());
  //  setBtnDisable(false)
  }
   
  };

  return (
    <div>
      <Tippy
        placement="top"
        className="tippy-tooltip"
        theme="light"
        content="Edit"
      >
        <img
          src={edit}
          onClick={()=>{
            toggleShow()
            getStakeholder(proId);
           
          }}
          className="action"
          style={{ cursor: "pointer" }}
          alt="edit icon"
        />
      </Tippy>

      <Offcanvas

        className="edit-app-canvas"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header>
          <Offcanvas.Title className="edit-app">
            <span>Edit Application</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Form.Label
          className="edit-app-label"
          htmlFor="basic-url"
        >
          Project Name
        </Form.Label>
        <span>
          <Form.Select
            name="Select project"
            className="ed-app-story-box"
            onChange={(event) =>{
              setProject_id(event.target.value)
              getStakeholder(event.target.value)
              if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {project_id, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,project_id:"Cannot be blank"}
        return changes
       })
     } 
            } }
            value={project_id}
          >
            {projectName.map((data) => {
              return (
                <option 
                value={data._id} 
                className="opt"
                selected={data._id === project_id ? true : false}
                >
                  
                  {data.name}
                </option>
              );
            })}
          </Form.Select>
          {formErrors.project_id && (
            <span className="error">{formErrors.project_id}</span>
          )}
        </span>

        <br />
        <Form.Label
          className="edit-app-label"
          htmlFor="basic-url"
        >
          Application Name
        </Form.Label>
        <span>
          <Form.Control
            value={name}
            type="text"
            placeholder="Enter application name"
            className="ed-app-story-box"
            onChange={(event) =>{
              setName(event.target.value)
              if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {name, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,name:"Cannot be blank"}
                return changes
               })
             }   
            } }
          />
          {formErrors.name && (
            <span className="error">{formErrors.name}</span>
          )}
        </span>

        <br />
        <Form.Label
          className="edit-app-label"
          htmlFor="basic-url"
        >
          Project Description
        </Form.Label>
        <span>
          <Form.Control
            as="textarea"
            type="text"
            placeholder="Enter an project description"
            className="ed-app-desc-box"
            onChange={(event) =>{
              setDescription(event.target.value)
              if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {description, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,description:"Cannot be blank"}
                return changes
               })
             }  
            } }
            value={description}
          />
          {formErrors.description && (
            <span className="error">{formErrors.description}</span>
          )}
        </span>
        <br />
        <Form.Label
          className="edit-app-label"
          htmlFor="basic-url"
        >
          Select Application Type
        </Form.Label>
        <span>
          <Form.Select
            name="Select Application Type"
            className="ed-app-story-box"
            onChange={(event) =>{
              setType(event.target.value)
              if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {type, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,type:"Cannot be blank"}
        return changes
       })
     }  
            } }
            value={type}
          >
            <option value="Web" className="opt">
              Web
            </option>
            <option value="Desktop" className="opt">
              Desktop
            </option>
            <option value="Mobile" className="opt">
              Mobile
            </option>
          </Form.Select>
          {formErrors.type && (
            <span className="error">{formErrors.type}</span>
          )}
        </span>
        <br />
        <Form.Label
          className="edit-app-label"
          htmlFor="basic-url"
        >
          Select Stakeholder
        </Form.Label>

        <Multiselect className="EAPP-stake-select"
          displayValue="mail"
          selectedValues={checkTS}
          
          // onKeyPressFn={function noRefCheck() { }}
           onRemove={function noRefCheck(e) { 
            // arrList = e 
            setCheckTS(e)
            
            // if(e.length === 0){
            //   setFormErrors(current => {
            //     const changes =  {...current,stakeholder:"Cannot be blank"}
            //     return changes
            //    })
            //  } 
             }}

          // onSearch={function noRefCheck() { }}
          onSelect={function noRefCheck(e) {
            // arrList = e;
            setCheckTS(e)
            
            // if(e.length !== 0) {
            //   setFormErrors(current =>{
            //   const {stakeholder, ...rest} = current;
            //   return rest
            //   });
            //  }
            // setSelectedList([...e, ...selectedList])
          }}
          options={[...stakeholderlist]}
          showCheckbox
        />
        {/* {formErrors.stakeholder && (
            <span className="error">{formErrors.stakeholder}</span>
          )} */}
        {/*  <span>

          <Form.Control
            type="text"
            placeholder="Enter an project name"
            className="ed-app-story-box"
          /> 
        </span>*/}
        <br />
        <div>
          <input
            type="checkbox"
            onClick={onClick}
            style={{ margin: "5px", marginLeft: "20px", zoom: "1" }}
          />
          <span style={{ fontSize: "12px", margin: "5px" }}>
            Add JIRA Project
          </span>
        </div>
        <br />
        {showResults ? (
          <div className="">
            <Form.Label
              className="edit-app-label"
              htmlFor="basic-url"
            >
              JIRA URL
            </Form.Label>
            <span>
              <Form.Control
                type="text"
                className="ed-app-story-box"
                placeholder="Enter JIRA URL"
              />
            </span>
            <br />
            <Form.Label
              className="edit-app-label"
              htmlFor="basic-url"
            >
              JIRA Client ID
            </Form.Label>
            <span>
              <Form.Control
                type="text"
                placeholder="Enter JIRA Client ID"
                className="ed-app-story-box"
              />
            </span>
            <br />
            <Form.Label
              className="edit-app-label"
              htmlFor="basic-url"
            >
              JIRA Secret Key
            </Form.Label>
            <span>
              <Form.Control
                type="password"
                placeholder="Enter JIRA Secret Key"
                className="ed-app-story-box"
              />
            </span>
          </div>
        ) : null}
        <span className="edit-application-button">
          <button
            type="button"
            className="edit-app-button"
            onClick={() => editApplication()}
          >
            Save
          </button>
          <button
            type="button"
            className="cancel-ed-app-button"
            onClick={() => handleClose()}
          >
            Cancel
          </button>
        </span>
      </Offcanvas>
    </div>
  );
}

export default function EditApplication({ editApp, status }) {
  useEffect(() => { }, [editApp]);
  return (
    <>
      {options.map((props, idx) => (
        <OffCanvasExample
          key={idx}
          {...props}
          placement={props.placement}
          editValue={editApp}
          status = { status}
        />
      ))}
    </>
  );
}