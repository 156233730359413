import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
//import Tippy from "@tippyjs/react";
import "./CreateSchedule.css";
import { GoPlus } from "react-icons/go";
import Form from "react-bootstrap/Form";
import chrome from "./../../../assets/Icons/Chrome.svg";
import safari from "./../../../assets/Icons/Safari.svg";
import firefox from "./../../../assets/Icons/Firefox.svg";
import edge from "./../../../assets/Icons/Edge.svg";
import apple from "./../../../assets/Icons/iOS.svg";
import android from "./../../../assets/Icons/Android.svg";
import { useEffect } from "react";
import { API } from "../../../global";
import Multiselect from "multiselect-react-dropdown";

// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import ListItemText from '@mui/material/ListItemText';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import Checkbox from '@mui/material/Checkbox';

// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 2.5 + ITEM_PADDING_TOP,
//       width: 350,
//     },
//   },
// };

let arrList = [];
let mailList = [];

const options = [
  {
    name: "Disable backdrop",
    scroll: false,
    backdrop: false,
    placement: "end",
  },
];

function OffCanvasExample({ ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    // setCurentId(false)
  };
  const toggleShow = () => setShow((s) => !s);

  // const [projectlist, setProjectlist] = useState([]);
  // const [projectId, setProjectId] = useState("");
  const [environment, setEnvironment] = useState("");
  const [version, setVersion] = useState("");
  // const [schedule, setSchedule] = useState({});
  // const [tenant_id, setTenant_id] = useState('');
  const [frequency, setFrequency] = useState('');
  const [time, setTime] = useState('');
  const [date, setDate] = useState('');
  const [day, setSelectDay] = useState('')
  const [showDay, setShowDay] = useState(false)
  const [session, SetSelectSession] = useState('')
  const [ShowSession, setShowSession] = useState(false)
  const [testsuite_id, setTestsuite_id] = useState('');
  const [testsuiteList, setTestsuiteList] = useState([]);
  const [scheduleStake, setScheduleStake] = useState([]);
  // const [checkTS, setCheckTS] = useState([]);
  // const [selectedList, setSelectedList] = useState([]);

  // const [scheduleList, setScheduleList] = useState([])
  // const [currentId, setCurentId] = useState(false);
  let fff = { ...props }

  useEffect(() => {
    getTestSuite();
    getStakeholder();
  }, []);


  const handleEnvironment = (event) => {
    setEnvironment(event.target.value);
  };
  const handleVersion = (event) => {
    setVersion(event.target.value);
  };
  //tests/testsuites/schedule/getid/100
  const handleSuiteId = (event) => {
    setTestsuite_id(event.target.value)
  };

  const handleFrequency = (event) => {
    setFrequency(event.target.value);
    if (event.target.value === 'Weekly') {
      setShowDay(true)
      setShowSession(false);
    } if (event.target.value === 'Monthly') {
      setShowSession(true);
      setShowDay(false)
    }
    if (event.target.value === 'Daily') {
      setShowDay(false);
      setShowSession(false);
    }

  };

  const handleDay = (event) => {
    setSelectDay(event.target.value);
  };
  const handleSession = (event) => {
    SetSelectSession(event.target.value);
  };

  //getting stakeholder API

  const getStakeholder = () => {
    // setLoading(true)
    fetch(`${API}/admin/stakeholder`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      .then((pro) => {
        setScheduleStake(pro.data)
        console.log(pro.data, "stakeholder");
      })
    // .then(() => setLoading(false))
  };
  // const checkList = (e) => {
  //   let arr = [...checkTS];
  //   const value = e;
  //   const index = arr.findIndex((val) => val === value);
  //   if (index > -1) {
  //     arr = [...arr.slice(0, index)];
  //   } else {
  //     arr.push(value);
  //   }
  //   setCheckTS(arr);
  // };

  const scheduleObj = {
    start_date: date,
    frequency: frequency,
    time: time,
    day: day,
    session: session,
    testsuite_id: testsuite_id,
    environment: environment,
    version: version,
    stakeholder: mailList,
    created_by: localStorage.getItem('user-id'),
    updated_by: localStorage.getItem('user-id')
  }


  const postSchedule = () => {
    console.log(scheduleObj);
    arrList.map((ar) => (
      mailList.push(ar.mail)
    ))
    if(date ==='' || frequency === '' || time === '' || testsuite_id === '' || environment === '' || version === '' || mailList.length === 0){
      if(day ==='' ||session ===''){
        alert('Fill the Details');
        mailList = [];
      }
      
    }else{
     fetch(`${API}/tests/testsuites/schedule`, {
      method: "POST",
      body: JSON.stringify(scheduleObj),
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }).then(() => {
      mailList = [];
      fff.getApp();
      handleClose();
     
     
    }).catch((err) => {
      console.log(err);
    })
    }
    

  }
  const getTestSuite = () => {

    fetch(
      `${API}/tests/testsuite/application/?application_id=${localStorage.getItem("app_id")}&project_id=${localStorage.getItem("project-id")}`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    )
      .then((val) => val.json())
      .then((app) => {
        console.log(app.data);
        app.data.length > 0 ? setTestsuiteList(app.data) : setTestsuiteList([]);
        app.data.length === 1 && setTestsuite_id(app.data[0]._id);
      })
  }

  // const getApplication = () => {
  //   fetch(
  //     `${API}/tests/testsuites/schedule/getid/${currentId ? fff.scheduleId : fff.scheduleId + 1}`,
  //     {
  //       method: "GET",
  //       // headers: {
  //       //   authorization: `${localStorage.getItem("access-key")}`,
  //       // },
  //     }
  //   )
  //     .then((val) => val.json())
  //     .then((app) => {
  //       app.data.length > 0 ? setScheduleList(app.data) : setScheduleList([]);
  //     })
  // }


  const [selectImg, setSelectImg] = useState(false);

  const handleClick = () => {
    setSelectImg(!selectImg);
  }

  return (
    <>
      <button
        type="button"
        onClick={toggleShow}
        className="CSH-create-btn test-start"
      >
        <span>
          <GoPlus className="add-icon me-1" />
        </span>
        Create Schedule
      </button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        className="CSH-Canvas-create-head"
      >
        <Offcanvas.Body>
          <Offcanvas.Title className="CSH-create mt-4">
            Create Schedule
          </Offcanvas.Title>
          <Form.Group>
            <Form.Label className="create-label mt-4">
              Select Test Suite
            </Form.Label>
             <Form.Select
              type="text"
              value={testsuite_id}
              className="select-cr-CSH-env"
              required
              onChange={handleSuiteId}
            >
            <option value="" disabled selected>Select the Test Suite</option>
              {
                testsuiteList.map(list => (
                  <option value={list._id}>
                  
                    {list.name}
                  </option>
                ))
              }
            </Form.Select>
          </Form.Group>

          <Form.Group className="mt-3" value={environment}>
            <Form.Label className="CSH-edit-label">
              Select Environment
            </Form.Label>
            <Form.Select
              as="select"
              className="select-cr-CSH-env"
              value={environment}
              onChange={handleEnvironment}
            >
            <option value="" disabled>Select Environment</option>
              <option value="Alpha Testing Environment">
                Alpha Testing Environment
              </option>
              <option value="Dev Environment">Dev Environment</option>
              <option value="Cloud Environment">Cloud Environment</option>
              <option value="Performance Testing Environment">
                Performance Testing Environment
              </option>
              <option value="System Integrating Testing(SIT)">
                System Integrating Testing(SIT)
              </option>
              <option value="User Acceptance Testing(UAT)">
                User Acceptance Testing(UAT)
              </option>
              <option value="Security Testing">Security Testing</option>
            </Form.Select>
          </Form.Group>

          <div className="browser-select d-flex mt-3">
            <div className="mt-3">
              <Form.Label htmlFor="basic-url" className="create-label">
                Select Browser / Mobile OS
              </Form.Label>
              <div className="browser-icon d-flex"
                onClick={handleClick}
                style={{ opacity: selectImg ? "0px" : "0.3px" }}
              >
                <img
                  src={chrome}
                  style={{ cursor: "pointer" }}
                  alt="chrome icon"
                  onClick={handleClick}
                // style={{ opacity: selectImg ? '0px' : '0.3px' }}
                />
                <img
                  src={safari}
                  style={{ cursor: "pointer", opacity: '0.5' }}
                  alt="safari icon"
                />
                <img
                  src={firefox}
                  style={{ cursor: "pointer", opacity: '0.5' }}
                  alt="firefox icon"
                />
                <img src={edge} style={{ cursor: "pointer", opacity: '0.5' }} alt="edge icon" />
                <img
                  src={apple}
                  style={{ cursor: "pointer", opacity: '0.5' }}
                  alt="apple icon"
                />
                <img
                  src={android}
                  style={{ cursor: "pointer", opacity: '0.5'}}
                  alt="android icon"
                />
              </div>
            </div>
            <div className="browser-version ms-4 mt-3">
              <Form.Label htmlFor="basic-url" className="browser-label">
                Select Version
              </Form.Label>

              <Form.Group>
                <Form.Select
                  className="CSH-version-select d-flex"
                  value={version}
                  onChange={handleVersion}
                  required
                >
                <option value="" disabled>Select Version</option>
                  <option value="Chrome 100">Chrome 100</option>
                  <option value="Chrome 99">Chrome 99</option>
                  <option value="Chrome 98">Chrome 98</option>
                  <option value="Chrome 97">Chrome 97</option>
                  <option value="Chrome 96">Chrome 96</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>

          <Form.Group className="mt-3" value={environment}>
            <Form.Label className="CSH-edit-label">
              Assign StakeHolders
            </Form.Label>

            <Multiselect className="CSH-stake-select"
              displayValue="mail"
              // onKeyPressFn={function noRefCheck() { }}
              // onRemove={function noRefCheck() { }}
              // onSearch={function noRefCheck() { }}
              onSelect={function noRefCheck(e) {
                arrList = e
                // setSelectedList([...e, ...selectedList])
              }}
              options={[...scheduleStake]}
              showCheckbox
            />

            {/* <Form.Select
            
              className="select-cr-CSH-env"
              value={environment}
              onChange={handleEnvironment}>


              {scheduleStake.map(list => (
                <option value={list.mail}>
                  
                  {list.mail}
                  </option>
              ))}
            </Form.Select> */}
          </Form.Group>

          <div className="CSH-Date mt-3 d-flex w-100">
            <div className="CSH-DP-head">
              <Form.Label htmlFor="basic-url" className="CSH-datePicker">
                Start Date
              </Form.Label>

              <div className="CSH-DP-input mr-2">
                <Form.Control type="date" id="date" name="date" className="CSH-form"
                  onChange={(event) => setDate(event.target.value)}
                />
              </div>
            </div>

            <div className="CSH-FR-head d-flex">
              <Form.Group className="w-100">
                <Form.Label htmlFor="basic-url">Frequency</Form.Label>
                <Form.Select
                  className="CSH-cr-version-select d-flex"
                  value={frequency}
                  onChange={handleFrequency}
                >
                  <option value="" disabled>Select Frequency</option>
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>

          <div className="FR-sec d-flex">
            <Form.Control type="time" id="time" name="time" className="CSH-time"
              onChange={(event) => setTime(event.target.value)}
            />

            {showDay && <Form.Group>
              <Form.Select
                className="CSH-day-version-select d-flex "
                value={day}
                onChange={handleDay}

              >
                <option value="" disabled>select a day</option>
                <option value="Sunday">Sunday</option>
                <option value="Monday">Monday</option>
                <option value="Tuesday">Tuesday</option>
                <option value="Wednesday">Wednesday</option>
                <option value="thursday">thursday</option>
                <option value="Friday">Friday</option>
                <option value="Saturday">Saturday</option>
              </Form.Select>
            </Form.Group>}

            {/*Select session if chose month */}

            {ShowSession && <Form.Group>
              <Form.Select
                className="CSH-sess-version-select d-flex"
                value={session}
                onChange={handleSession}
              >
                <option value="" disabled>select a session</option>
                <option value="Start of Month">Start of Month</option>
                <option value="End of Month">End of Month</option>
              </Form.Select>
            </Form.Group>}
          </div>

          <div className="CSH-suite-buttons justify-content-end ml-3">
            <button className="save-CSH" onClick={() => postSchedule()}>Schedule</button>
            <button className="CSH-cancel-ts" onClick={()=> {
              handleClose();
              setDate("")
              setFrequency("")
              setEnvironment("")
              setVersion("")
              setTestsuite_id("")
              setScheduleStake([])
              setShowDay("")
              setSelectDay("") 
              setShowDay(false)
              setShowSession(false)
              setTime("")
              SetSelectSession("")
              }}>
              Cancel
            </button>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function CreateSchedule({ getSchedule, count }) {

  return (
    <>
      {options.map((props, idx) => (
        <OffCanvasExample
          key={idx}
          getApp={getSchedule}
          scheduleId={count}
          {...props}
        />
      ))}
    </>
  );
}
