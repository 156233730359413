import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./OverviewAdmin.css";
import { useState } from "react";
import { useParams } from "react-router";
import { useEffect } from "react";
import { API } from "../../../../global";
let slidesToShow = 5;

const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <span style={{ color: "#805382", fontSize: "30px" }}>
            <i className="bi bi-chevron-left"></i>
          </span>
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  // console.log(props);
  return (
    <>
      {currentSlide !== slideCount - slidesToShow && (
        <div className={className} onClick={onClick}>
          <span style={{ color: "#805382", fontSize: "30px" }} className="nextArr-ov">
            <i className="bi bi-chevron-right"></i>
          </span>
        </div>
      )}
    </>
  );
};
const carouselProperties = {
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  slidesToShow: slidesToShow,
  slidesToScroll: 2,
  infinite: false,
  // slidesToScroll={3}
  responsive: [
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        centerMode: false,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        centerMode: false,
        slidesToScroll: 2,
      },
    },
  ],
};

function OverviewAdmin() {
  const {context} = useParams();

  const [width, setWidth] = useState(window.innerWidth);
  const [ptc, setPTC] = useState(true);
  const [ftc, setFTC] = useState(true);
  const [nrt, setNRT] = useState(true);
  const [testcaseCount,setTestcaseCount]= useState(0);
  const [testsuiteData,setTestsuiteData]= useState({});
  const [projectCount,setProjectCount]= useState(0);
  const [applicationCount,setApplicationCount]= useState(0);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  console.log(testcaseCount);
  // console.log(testsuiteCount);
  console.log(projectCount);
  console.log(applicationCount);

  useEffect(() => {
 // eslint-disable-next-line
     getProjectCount();
    getApplicationCount();
    getTestcaseCount();
    getTestsuiteCount();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
     // eslint-disable-next-line
  }, [context]);

  const getProjectCount = async()=>{
  await fetch(`${API}/admin/project`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      .then((projCount) => setProjectCount(projCount.count))
  }

  const getApplicationCount = async()=>{
    await fetch(`${API}/admin/application/${context ? context : localStorage.getItem('project-id')} `, {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      })
        .then((value) => value.json())
        .then((appCount) => setApplicationCount(appCount.data.length))
    }

  const getTestcaseCount = async()=>{
    await fetch(`${API}/tests/testcases`,{
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }).then((value) => value.json())
    .then((testCount)=>setTestcaseCount(testCount.count))
  }

  const getTestsuiteCount= async()=>{
    fetch(`${API}/tests/testsuite/testlog/?project_id=${context ? context : localStorage.getItem('project-id') }`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }).then((data) => data.json())
      .then((tsuiteCount) => setTestsuiteData(tsuiteCount))
  }



  if (width <= 426) {
    slidesToShow = 1;
  } else if (width > 426 && width <= 769) {
    slidesToShow = 3;
  } else if (width > 769 && width <= 1025) {
    slidesToShow = 4;
  } else {
    slidesToShow = 5;
  }

  return (
    <div 
    style={{ paddingRight: "1%" }}
    className="carousel overflow-hidden Overview-admin-carousel">
      <div className="admin-overview_header">
        <span>Overview</span>
        {/* <span>1 Aug 2022 - 8 Aug 2022</span> */}
      </div>
      <Slider {...carouselProperties}>
        {/* <div className="slides slide_01">
          <h3>{projectCount}</h3>
          <h4>Projects</h4>
        </div> */}
        <div className="slides slide_0">
          <h3>{applicationCount}</h3>
          {/* <h3>{applicationCount}</h3> */}
          <h4>APPLICATIONS</h4>
        </div>

        <div className="slides slide_1">
          
            <h3>{testsuiteData.count}</h3>
          {/* <h3>{testsuiteCount}</h3> */}
          <h4>TEST SUITES</h4>
        </div>
        <div className="slides slide_2">
           
          <h3>{testsuiteData.totalCase}</h3>
          {/* <h3>{testcaseCount}</h3> */}
          <h4>TEST CASES</h4>
        </div>
        <div className="slides slide_3" onClick={(e) => setPTC(!ptc)}>
          <h3>{ptc ? testsuiteData.passedCaseCount:testsuiteData.passedPercentage ? testsuiteData.passedPercentage : 0 }</h3>
          {/* <h3>{ptc ? 4143 : 37}</h3> */}
          <h4>
            PASSED TEST CASES{" "}
            <span style={ptc ? { color: "#7EB96566" } : { color: "#7EB965" }}>
              {" "}
              
            </span>
          </h4>
        </div>
        <div className="slides slide_4" onClick={(e) => setFTC(!ftc)}>
          {/* <h3>{ftc ? 113 : 23}</h3> */}
          <h3>{ ftc ? testsuiteData.failedCaseCount : testsuiteData.failedPercentage ?testsuiteData.failedPercentage : 0}</h3>
          <h4>
            FAILED TEST CASES{" "}
            <span style={ftc ? { color: "#DC354566" } : { color: "#dc3545" }}>
              {" "}
              
            </span>{" "}
          </h4>
        </div>
        <div className="slides slide_5" onClick={(e) => setNRT(!nrt)}>
          <h3>{nrt ? testsuiteData.noruncases:testsuiteData.noRunCasesPercentage ? testsuiteData.noRunCasesPercentage : 0}</h3>
          {/* <h3>{nrt ? 43 : 17}</h3> */}
          <h4>
            NO RUN TEST CASES{" "}
            <span style={nrt ? { color: "#77777766" } : { color: "#777777" }}>
              {" "}
              
            </span>
          </h4>
        </div>
        {/* <div className="slides slide_6">
          <h3>50%</h3>
          <h4>AUTOMATION TEST COVERAGE</h4>
        </div>
        <div className="slides slide_7">
          <h3>40%</h3>
          <h4>AUTOMATION STABILITY</h4>
        </div> */}
      </Slider>
    </div>
  );
}

export default OverviewAdmin;
