import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import "./CreateTestsuite.css";
import { GoPlus } from "react-icons/go";
import chrome from "./../../../assets/Icons/Chrome.svg";
import safari from "./../../../assets/Icons/Safari.svg";
import firefox from "./../../../assets/Icons/Firefox.svg";
import edge from "./../../../assets/Icons/Edge.svg";
import apple from "./../../../assets/Icons/iOS.svg";
import android from "./../../../assets/Icons/Android.svg";
import { API } from "./../../../global";
import Multiselect from "multiselect-react-dropdown";


const options = [
  {
    scroll: false,
    backdrop: false,
    placement: "end",
  },
];

function OffCanvasExample({ ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);
  // const [testsuitelist, setTestsuitelist] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [environment, setEnvironment] = useState("");
  const [version, setVersion] = useState("");
  const [testcaselist, setTestcaselist] = useState([]);
  // const [searchTS, setSearchTS] = useState("");
  const [checkTS, setCheckTS] = useState([]);
  const [projectlist, setProjectlist] = useState([]);
  const [formErrors, setFormErrors] = useState({});

  const [applist, setApplist] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [applicationId, setApplicationId] = useState("");
  let arrList = [];
  let mailList = [];


  const newTestsuite = {
    name: name,
    description: description,
    last_run_status: "Pending",
    tenant_id: "04527940-52a6-4f78-9bc4-253f9546b748",
    testsuite_id:Number(localStorage.getItem('tsID')) + 1,
    environment: environment,
    version: version,
    test_cases: mailList,
    project_id: projectId,
    application_id: applicationId,
    created_by: localStorage.getItem("user-id"),
    updated_by: localStorage.getItem("user-id"),
  };
  const validate = () => {

    let errors = {};
    if (!newTestsuite.name) {
      errors.name = "Cannot be blank";
    } 
    if (!newTestsuite.environment) {
      errors.environment = "Cannot be blank";
    } 
    if (!newTestsuite.test_cases) {
      errors.test_cases = "Cannot be blank";
    } 
    if (!newTestsuite.description) {
      errors.description = "Cannot be blank";
    } 
    if (!newTestsuite.version) {
      errors.version = "Cannot be blank";
    } 
    
    if (!newTestsuite.project_id) {
      errors.project_id = "Cannot be blank";
    } 
    if (!newTestsuite.application_id) {
      errors.application_id = "Cannot be blank";
    } 
   
    return errors;
  };
  const handleProjectId = (event) => {

    setProjectId(event.target.value);
    getApplication(event.target.value);
    if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {project_id, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,project_id:"Cannot be blank"}
        return changes
       })
     } 
   
  };
  const handleApplicationId = (event) => {
    setApplicationId(event.target.value);
    getTestcases(event.target.value)
    if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {project_id, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,application_id:"Cannot be blank"}
        return changes
       })
     } 
  };
  const getProjects = () => {
    const userId= localStorage.getItem('user-id')

    fetch(`${API}/admin/project`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      //.then((data) => console.log(data.data))
      .then((pro) => {
        let projectData = [...pro.data];
        console.log(projectData,'project');
        let not_empty=[];
        let userList = [];
        let assign_project_list = []
        projectData.forEach((d,i)=>{
        if(d.project_users.length !==0){
          not_empty.push({i:i, ...d})
         }
        })
        not_empty.forEach((a)=>{
           userList.push(a.project_users)
       
        })
         const assign_project = userList.flat().filter(d=>d.user_id===userId);
        projectData.forEach((d)=>{
         if(assign_project.some(a=>a.project_id===d._id)){
           assign_project_list.push(d)
         }
        })
        // console.log(pro.data);
        setProjectlist(assign_project_list);
        assign_project_list.length > 0 && proId === null && setProjectId(assign_project_list[0]._id);
      });
    // .then(() => getApplication());
    //setLoading(false);
  };
  const getApplication = (val) => {
    setApplist([]);
    fetch(`${API}/admin/application/${val}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((val) => val.json())
      .then((app) => {
        app.data.length > 0 ? setApplist(app.data) : setApplist([]);
        // app.data.length > 0 && appId === null &&
          // setApplicationId(app.data[0]._id)

      });
  };

  const getTestcases = (id) => {
    setTestcaselist([]);
    fetch(`${API}/tests/testcases/application/?application_id=${id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }) //promise
      .then((value) => value.json())
      .then((tcs) => {
        // tcs.data.map((test)=> test.published )
        const publish = tcs.data.filter((test)=> test.published)
        publish.forEach(option=>{
          option.displayValue = 'TC'+ option.test_case_id + "\t" + option.test_case_name
       })
        setTestcaselist(publish);
      });
  };

  const addTestsuite = () => {
  
    // arrList.map((ar) => (
    //   mailList.push(ar._id)
    // ))
    console.log(arrList)
    checkTS.map((ar) => (
      mailList.push(ar._id)
    ))
    validate()
    if(Object.keys(validate()).length === 0){
      fetch(`${API}/tests/testsuites`, {
        method: "POST",
        body: JSON.stringify(newTestsuite),
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }).then(() => {
        handleClose();
        window.location.reload();
      });
    }else{
      setFormErrors(validate());
    }
  };
  const handleEnvironment = (event) => {
    
    setEnvironment(event.target.value);
    if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {environment, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,environment:"Cannot be blanck"}
        return changes
       })
     }  

  };
  const handleVersion = (event) => {
    setVersion(event.target.value);
    if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {version, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,version:"Cannot be blanck"}
        return changes
       })
     }  
  };

  const appId = localStorage.getItem("app_id")
  const proId = localStorage.getItem('project-id')
  useEffect(() => {
    getProjects();
    if (proId) {
      getApplication(proId);
      setProjectId(proId)
    }
    if (proId && appId) {
      setApplicationId(appId)
      // getTestcases(appId);
    }
    // eslint-disable-next-line
  }, [proId, appId]);


  // useEffect(() => {
  //   getTestcases();

  // const checkList = (e) => {
  //   let arr = [...checkTS];
  //   const value = e;
  //   const index = arr.findIndex((val) => val === value);
  //   if (index > -1) {
  //     arr = [...arr.slice(0, index)];
  //   } else {
  //     arr.push(value);
  //   }
  //   setCheckTS(arr);
  // };

  return (
    <>
      <button
        type="button"
        onClick={()=>{
          toggleShow()
          getTestcases(appId)}}
        className="test-suite-create-btn"
      >
        <span>
          <GoPlus className="add-icon me-1" />
        </span>
        Create Test Suite
      </button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        className="TS-Canvas-create-head"
      >
        <Offcanvas.Body>
          <Offcanvas.Title className="ts-create mt-3">
            Create Test Suite
          </Offcanvas.Title>
          <Form.Group>
            <Form.Label className="create-label mt-3">
              Select Project
            </Form.Label>
            <Form.Select
              type="text"
              placeholder="Select Project"
              className="TS-create-form select-ts-env"
              required
              value={projectId}
              onChange={handleProjectId}
            >
              {projectlist.map((data, i) => (
                <option
                  selected={data._id === projectId ? true : false}
                  value={data._id}
                >
                  {data.name}
                </option>
              ))}
            </Form.Select>
            {formErrors.project_id && (
            <span className="error">{formErrors.project_id}</span>
          )}
          </Form.Group>
          <Form.Group>
            <Form.Label className="create-label mt-2">
              Select Application
            </Form.Label>
            <Form.Select
              type="text"
              placeholder="Select Application"
              className="TS-create-form select-ts-env"
              required
              value={applicationId}
              onChange={handleApplicationId}
            >
              <option value="" disabled>Select Application</option>
              {applist.map((data, i) => (
                <option
                  key={i}
                  selected={data._id === applicationId ? true : false}
                  value={data._id}
                >
                  {data.name}
                </option>
              ))}
            </Form.Select>
            {formErrors.application_id && (
            <span className="error">{formErrors.application_id}</span>
          )}
          </Form.Group>
          <Form.Label htmlFor="basic-url" className="create-label mt-2">
            Test Suite Name
          </Form.Label>
          <input
            type="text"
            placeholder="Enter Test Suite Name"
            className="form-control ts-form TS-create-form"
            required
            onChange={(event) =>{
             setName(event.target.value) 
             if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {name, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,name:"Cannot be blank"}
                return changes
               })
             }  
              }}
          
          />
          {formErrors.name && (
            <span className="error">{formErrors.name}</span>
          )}
      
          <Form.Label htmlFor="basic-url" className="create-label mt-2">
            Test Suite Description
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Description"
            className="form-control ts-form TS-create-form"
            onChange={(event) =>{
              setDescription(event.target.value)
              if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {description, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,description:"Cannot be blank"}
                return changes
               })
             }  
              }}
            
            required
          />
          {formErrors.description && (
            <span className="error">{formErrors.description}</span>
          )}

          <Form.Group
            className="mt-2 TS-create-form"
            value={environment}
            onChange={handleEnvironment}
            required
          >
            <Form.Label className="create-label">Select Environment</Form.Label>
            <Form.Select className="select-ts-env">
            <option value="" disabled selected>
                Select Environment
              </option>
              <option value="Alpha Testing Environment">
                Alpha Testing Environment
              </option>
              <option value="Dev Environment">Dev Environment</option>
              <option value="Cloud Environment">Cloud Environment</option>
              <option value="Performance Testing Environment">
                Performance Testing Environment
              </option>
              <option value="System Integrating Testing(SIT)">
                System Integrating Testing(SIT)
              </option>
              <option value="User Acceptance Testing(UAT)">
                User Acceptance Testing(UAT)
              </option>
              <option value="Security Testing">Security Testing</option>
            </Form.Select>
            {formErrors.environment && (
            <span className="error">{formErrors.environment}</span>
          )}
          </Form.Group>

          <div className="browser-select d-flex mt-3">
            <div className="mt-3">
              <Form.Label htmlFor="basic-url" className="create-label">
                Select Browser / Mobile OS
              </Form.Label>
              <div className="browser-icon d-flex">
                <img
                  src={chrome}
                  style={{ cursor: "pointer" }}
                  alt="chrome icon"
                />
                <img
                  src={safari}
                  style={{ cursor: "pointer" }}
                  alt="safari icon"
                />
                <img
                  src={firefox}
                  style={{ cursor: "pointer" }}
                  alt="firefox icon"
                />
                <img src={edge} style={{ cursor: "pointer" }} alt="edge icon" />
                <img
                  src={apple}
                  style={{ cursor: "pointer" }}
                  alt="apple icon"
                />
                <img
                  src={android}
                  style={{ cursor: "pointer" }}
                  alt="android icon"
                />
              </div>
            </div>
            <div className="browser-version ms-4 mt-3">
              <Form.Label htmlFor="basic-url" className="browser-label">
                Select Version
              </Form.Label>

              <Form.Group>
                <Form.Select
                  className="version-select d-flex"
                  value={version}
                  onChange={handleVersion}
                  required
                >
                 <option value="" disabled>
                Select Version
              </option>
                  <option value="Chrome 100">Chrome 100</option>
                  <option value="Chrome 99">Chrome 99</option>
                  <option value="Chrome 98">Chrome 98</option>
                  <option value="Chrome 97">Chrome 97</option>
                  <option value="Chrome 96">Chrome 96</option>
                </Form.Select>
                {formErrors.version && (
            <span className="TSCR-error">{formErrors.version}</span>
          )}
              </Form.Group>
            </div>
          </div>
          <div className="ts-link mt-2">
            <Form.Label htmlFor="basic-url" className="create-label">
              Link Test Case
            </Form.Label>
            <Multiselect className="CAPP-stake-select"
                   displayValue="displayValue"
                   showArrow	= "true"
                 
          // selectedValues={finalArr}
          // onKeyPressFn={function noRefCheck() { }}
        
          avoidHighlightFirstOption= 'false'
          // // onSearch={function noRefCheck() { }}
          onSelect={function noRefCheck(e) {
            setCheckTS(e)
            arrList = e;
            if(e.length !== 0) {
              setFormErrors(current =>{
              const {test_cases, ...rest} = current;
              return rest
              });
             }

            // setSelectedList([...e, ...selectedList])
          }}
             onRemove={function noRefCheck(e) {
              if(e.length === 0){
              setFormErrors(current => {
                const changes =  {...current,test_cases:"Cannot be blank"}
                return changes
               })
             }  
               }}
          options={[...testcaselist]}
          showCheckbox
        />
        {formErrors.test_cases && (
            <span className="error">{formErrors.test_cases}</span>
          )}
            {/* <div className="mt-2">
              <input
                type="search"
                placeholder="Search"
                className="form-control ts-form search-bar ms-3"
                required
                onChange={(e) => setSearchTS(e.target.value)}
              />
              <ul
                className="Create-TS-li"
                style={{
                  listStyle: "none",
                  width: "94%",
                  height: "100%",
                  overflow: "auto",
                  background: "white",
                  marginLeft: "17px",
                  borderRadius: "5px",
                }}
              >
                {testcaselist.length > 0 ? (
                  testcaselist
                    .filter((tf) => {
                      if (searchTS === "" || searchTS.trim() === "") {
                        return tf;
                      } else if (
                        tf.test_case_name
                          .toLowerCase()
                          .includes(searchTS.toLowerCase())
                      ) {
                        return tf;
                      }
                      return false;
                    })
                    .map((tf, i, array) => (
                      tf.published && <li key={i}>
                        <input
                          type="checkbox"
                          value={tf._id}
                          onClick={(e) => checkList(e.target.value)}
                        />{" "}
                        &nbsp;  {tf.published && `TC ${tf.test_case_id}`}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{tf.published && tf.test_case_name}
                      </li>
                    ))
                ) : (
                  <li>No Data Found</li>
                )}
          
              </ul>
            </div> */}
            <div className="suite-buttons justify-content-end ml-3">
              <button
                className="save-ts"
                onClick={() => {
                  addTestsuite(newTestsuite);
                }}
              >
                Create
              </button>
              <button className="cancel-ts" onClick={()=>{
                handleClose();
                setName("")
                setDescription("")
                setEnvironment("")
                setVersion("")
                setFormErrors({})
                // setCheckTS([])
                
                }}>
                Cancel
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function CreateTsButton({ getTestsuites }) {
  return (
    <>
      {options.map((props, idx) => (
        <OffCanvasExample
          key={idx}
          {...props}
          placement={props.placement}
          testsuites={getTestsuites}
        />
      ))}
    </>
  );
}
