import "./AdminTopgreen.css";
import { Nav } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";

function AdminTopgreen() {
  return (
    <nav className="navbar admin-top-green-navbar navbar-light">
      <span
        className="AD-navbar-brand"
      >
        Dashboard
      </span>
      <div className="d-flex justify-content-end dashboard-drop">
        <Nav.Link>
          <label className="admin-DHB-period position-absolute ms-4">Period</label>
         
            <Dropdown.Toggle id="dropdown-basic" className="dept-admin me-2 disabled">
              All time
            </Dropdown.Toggle>

            {/*
            <Dropdown.Menu className="adtg-nv-drop-menu text-center">
              <Dropdown.Item value="This month" className="ad-pr-drop">This month</Dropdown.Item>
              <Dropdown.Item value="Last 7 days" className="ad-pr-drop">Last 7 days</Dropdown.Item>
              <Dropdown.Item value="Last 30 days" className="ad-pr-drop">Last 30 days</Dropdown.Item>
              <Dropdown.Item value="Last 90 days" className="ad-pr-drop">Last 90 days</Dropdown.Item>
              <Dropdown.Item value="Lifetime" className="ad-pr-drop" >Lifetime</Dropdown.Item>
              <Dropdown.Item value="Custom" className="ad-pr-drop">Custom</Dropdown.Item>




            </Dropdown.Menu>
          </Dropdown> */}
          {/* <select
           className="form-select form-select-sm QE-s-option me-2"
           style={{
             width: "180px",
             height: "32px",
             borderRadius: "5px",
             border: "1px solid #CCCCCC ",
           }}
         >
           <option disabled>This month</option>
           <option value="Last 7 days">Last 7 days</option>
           <option value="Last 30 days">Last 30 days</option>
           <option value="Last 90 days">Last 90 days</option>
           <option value="Lifetime">Lifetime</option>
           <option value="Custom">Custom</option>
         </select> */}
        </Nav.Link>


      </div>
    </nav>
  );
}

export default AdminTopgreen;
