import React, { useEffect, useState } from "react";
import filter from "../../../../assets/Icons/Filter.svg";
import ReactTooltip from "react-tooltip";
import Table from "react-bootstrap/Table";
import "./ProjectList.css";
import DeleteTcButton from "../DeleteProject/DeleteProject";
import EditProject from "../EditProject/EditProject";
import CreateProject from "../CreateProject/CreateProject";
import { API } from "../../../../global";
import { Col, Container, Row } from "react-bootstrap";
import AdminSidebar from "../../AdminSidebar/AdminSiderbar";
//import Header from "../../../Layout/Navbar/Navbar";
import AdminNavbar from "../../AdminNavbar/AdminNavbar";
import { Oval } from "react-loader-spinner";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MdArrowDropUp } from "react-icons/md";
import { MdArrowDropDown } from "react-icons/md";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from '@mui/material/styles';


// import Pagination from '@mui/material/Pagination';
// import { useNavigate } from "react-router";

function ProjectList() {
  const [projectlist, setProjectlist] = useState([]);
  const [projectEdit, setProjectEdit] = useState({});
  const [loading, setLoading] = useState(true);
  const [expand, setExpand] = useState(false);
  const [sort, setSort] = useState("asc");
  const [page, setPage] = useState(0); 
  const [searchList, setSearchlist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); 
  const [sortedIds, setSortedIds] = useState([]);
  // const navigate = useNavigate();

  //Pagination style

  const theme = createTheme({
    components: {
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: '#5D3B5E',
              color: 'white',
              '&:hover': {
                backgroundColor: '#805382 !important',
              },
            },
            '&:not(.Mui-selected):hover': {
              backgroundColor: '#805382',
              color:"white"
          },
          },
        },
      },
    }
  });

  useEffect(() => {
    getProject();
const newIds = projectlist.map(project => project._id);
    setSortedIds(newIds);
    // eslint-disable-next-line
  }, []);
  // const token = localStorage.getItem("access-key");
  const getProject = (count) => {
    if(count === undefined ) count=currentPage
    let startCount=0;
    let endCount=0;
    let firstValue = count
    if(count===1){
      firstValue=undefined
    }else{
      startCount=(count-1)*10;
      endCount = startCount+10;
    }
    let paginationArr = [];
    fetch(`${API}/admin/project`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())

      .catch((error) => console.log(error))
      //  .then((data) => console.log(data.data))
      .then((pro) => {
        setPage(Math.ceil(pro.data.length/10));
         paginationArr = [...pro.data];
         if(firstValue){
          setProjectlist(paginationArr.slice(startCount, endCount))
          setSearchlist(paginationArr)
         }else{
          setProjectlist(paginationArr.slice(0, 10) )
          setSearchlist(paginationArr)
         }
      })
      .then(() => setLoading(false));
  };
  const getFilter =(value)=> {

    let addTstoIds = searchList.map((d,i)=>{
      return {...d, project_users:d.name, created_by:d.name, updated_by:d.name, list_id:`${"PID" + i + Number(1)}`}
    });
  
    const lowercasedValue = value.toLowerCase().trim();
  
    if (lowercasedValue.length === 0){
      setProjectlist(searchList)
    } else {
      const filteredData = addTstoIds.filter(item => {
         return Object.keys(item).some(key =>
          item[key].toString().toLowerCase().includes(lowercasedValue)
          );
      });
      // let removeTstoIds = filteredData.map((d)=>{
      //   return {...d, test_case_id : d.test_case_id.replace('TC', '')}
      // });
      setProjectlist(filteredData);
    }
  }   
  const handleChange = (event, value) => { 
    getProject(value)
    setCurrentPage(value)
    // setPage(value); 
};
  //Sorting function

  const sorting = (order) => {
    if (sort === "asc") {
      const sorted = [...projectlist].sort((a, b) =>
        a[order] > b[order] ? 1 : -1
        
      );
      setProjectlist(sorted);
      setSort("dsc");
    }
    if (sort === "dsc") {
      const sorted = [...projectlist].sort((a, b) =>
        a[order] < b[order] ? 1 : -1
      );
      setProjectlist(sorted);
      setSort("asc");
          const newIds = sorted.map(project => project._id);
    setSortedIds(newIds);
    }


  }

  const editProject = async (value) => {
    setProjectEdit(value);
  };
  const top_green = (
    <Container fluid>
      <Row className="project-test-row">
        <Col>
          <div className="d-flex first-head justify-content-start">
            Projects
          </div>
        </Col>

        <Col className="d-flex first-head justify-content-end">
          <CreateProject getProject={getProject}/>
        </Col>
      </Row>
    </Container>
  );
  var prolist;

  if (loading) {
    prolist = (
      <>
        {top_green}
        <div style={{ marginTop: "20%", marginLeft: "5%" }}>
          <Oval
            height={80}
            width={2000}
            color="#805382"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#F0E3F1#AB78AE"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      </>
    );
  } else if (projectlist.length === 0) {
    prolist = (
      <>
        {top_green}
        <div className="ts-found">
          <AiOutlineInfoCircle size="30px" color="#9F9F9F" />
          <h6 style={{ color: "#9F9F9F" }}>Please Create a Project</h6>
        </div>
      </>
    );
  } else {
    prolist = (
      <>
        {top_green}
        <Table className="pro-list-table">
          <thead
          className="align-middle PL-thead"
            style={{
              backgroundColor: "#E6C6E840",
              color: "#805382",
            }}
          >
            <tr>
              <th style={{ borderRight: "2px solid #805382", width: "20%" }}>
                Project ID{" "}
                <span onClick={() => {sorting(projectlist._id) || setExpand(!expand) }}>
                  {!expand ? (
                    <MdArrowDropDown size={15} />
                  ) : (
                    <MdArrowDropUp size={15} />
                  )}
                </span>
              </th>
              <th style={{ borderRight: "2px solid #805382", width: "20%" }}>
                Project Name{" "}
                <span onClick={() => {sorting(projectlist.name) || setExpand(!expand) }}>
                  {!expand ? (
                    <MdArrowDropDown size={15} />
                  ) : (
                    <MdArrowDropUp size={15} />
                  )}
                </span>
              </th>
              <th style={{ borderRight: "2px solid #805382", width: "20%" }}>
                Project Description
              </th>
              <th style={{ width: "20%" }}>
                <span style={{ marginLeft: "80px" }}>Actions</span>
                <img
                  src={filter}
                  style={{ marginLeft: "110px" }}
                  className="filter"
                  alt="filter icon"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {projectlist.map((project, i) => {
              return (
                <tr style={{ height: "10px" }} key={project._id}>
                  <td>{"PID" + (sortedIds.indexOf(project._id) + 1)}</td>
                  <td>
                    <Tippy
                      placement="top"
                      className="tippy-tooltip"
                      theme="light"
                      content={project.name}
                    >
                      <span data-tip="hello world">{project.name}</span>
                    </Tippy>
                  </td>
                  <td>
                    <Tippy
                      placement="top"
                      className="tippy-tooltip"
                      theme="light"
                      content={project.description}
                    >
                      <span data-tip="hello world">{project.description}</span>
                    </Tippy>
                  </td>
                  <td>
                    <div className="pro-icons">
                      <span onClick={() => editProject(project)}>
                        <EditProject id={project._id} editApp={projectEdit} getProject={getProject} />
                      </span>
                      <DeleteTcButton id={project._id} getProject={getProject} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  }

  return (
    <>
      {/* <Header />  */} 
      <AdminSidebar />
      <AdminNavbar testcaselist={getFilter} />
      <div className="pro-list">
        <ReactTooltip />
        <div>{prolist}</div>
      </div>
      <div className='page-head fixed-bottom'>

        <ThemeProvider theme={theme}>
          <Pagination count={page} page={currentPage} onChange={handleChange} variant="outlined" shape="rounded" /> 
          </ThemeProvider>
 </div>
      
    </>
  );
}

export default ProjectList;
