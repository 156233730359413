import "./Report.css";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Navbar from "../../Layout/Navbar/Navbar";
import { Col, Container, Row } from "react-bootstrap";
// import Mail from "../../../assets/Icons/Mail.svg";
import DownloadReport from "../../../assets/Icons/Download Report.svg";
import Back from "../../../assets/Icons/Back.svg";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../../global";
import React, { useEffect, useState } from "react";
import moment from "moment";
// import { PDFExport } from "@progress/kendo-react-pdf";
import { useRef } from "react";
import publish from "../../../assets/Icons/published.svg";


function TestcaseReport() {
  const [isDrawOpen, setIsDrawOpen] = useState(false);

  // const [passed_cases, setPassed_cases] = useState([]);
  // const [faild_cases, setFaild_cases] = useState([]);
  const [all_cases, setAll_cases] = useState([]);
  const [run_log_status, setRunLog] = useState([]);
  // const [application, setApplication] = useState({});
  // const [testsuite, setTestsuite] = useState({});
  // const [no_testcases_exe, setTestcasesExe] = useState("");
  // const [run_duration_testsuite, setRunDuration] = useState();
  const [all_log, setAllLog] = useState(true);
  const [all_log_data, setAllLogData] = useState({});
  const [test_name,setTest_name] = useState("")
  const [test_id,setTest_id] = useState("")
  const [dataId,setDataId] = useState("");
  const [searchList, setSearchlist] = useState([]);

  const [tick, setTick] = useState("");
  const [tickMark, setTickMark] = useState(false);
  const ref = useRef();  
  const {testId} = useParams();



  const navigate = useNavigate();

  const onClick = (val,i) => {
    // setIsDrawOpen(true)
    setTickMark(true);
    // console.log("data")
    // let uniqueId = val._id;
    // console.log(all_cases.indexOf(val) === i ,"data")
    setTick(val._id)
    setDataId(val._id)
    setRunLog([]);
  if(dataId ===''){

     setIsDrawOpen(true)
      
      }else if(dataId===val._id){
      
      setIsDrawOpen(current => !current)
  
      
     }else{
      
       setIsDrawOpen(true)
      
      
       }
    // setIsDrawOpen(current=> !current)
    // setIsDrawOpen(current => dataId === val._id ? !current : current)
    // setIsDrawOpen(dataId === val._id ? false : true)
    // setIsDrawOpen(all_cases.indexOf(val) !== i && false)
    // setIsDrawOpen((current) => all_cases.indexOf(val) === i && !current);
    // setIsDrawOpen()
    let log_parse = JSON.parse(val.run_log);
    if(log_parse.length > 0){
      setRunLog(log_parse[0].elements[0].steps);
      setAllLogData(log_parse[0].elements[0]);
      console.log(log_parse[0].elements[0]);
    }

  };
  useEffect(() => {
    testcase_duration();
    setAllLog(true)
 
    // eslint-disable-next-line
  }, []);


  // get testcase report by testcase Id
  const testcase_duration = () => {
    fetch(`${API}/tests/testcases/runlogs/${testId}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }) //promise
      .then((data) => data.json()) //Response Object
      .then((ts) => {
        // setRunDuration(ts.data.reduce((a, b) => +a + +b.run_duration, 0))
        console.log(ts.data[0].executed_by.Ad_username,"report")
        setAll_cases(ts.data);
        setTest_name(ts.data[0].test_case_id.test_case_name)
        setTest_id(ts.data[0].test_case_id.test_case_id)
        setSearchlist(ts.data);
      });
  };



// const testcaseId = localStorage.getItem("ts-id")

const onButtonClick = () => {
  // using Java Script method to get PDF file
  fetch('SamplePDF.pdf').then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'SamplePDF.pdf';
          alink.click();
      })
  })
}
const getFilter =(value)=> {
  // console.log(value, 'vlaue');
      let addTstoIds = searchList.map((d)=>{
        return( {executed_by:d.executed_by.Ad_username,test_case_id:'IQA',...d})
      });
      // console.log(addTstoIds, 'add');
  
      const lowercasedValue = value.toLowerCase().trim();
    
      if (lowercasedValue.length === 0){
        setAll_cases(searchList)
      } else {
        const filteredData = addTstoIds.filter(item => {
          return Object.keys(item).some(key =>
             item[key].toString().toLowerCase().includes(lowercasedValue)
          );
        });
        // let removeTstoIds = filteredData.map((d)=>{
        //   return {...d, testsuite_id : d.testsuite_id.replace('TS', '')-100}
        // });
        // console.log(removeTstoIds, 'remove');
  
        setAll_cases(filteredData);
      }
    }  


  return (
    <>
    <div id="download">
      <Navbar testcaselist={getFilter} />
      {/* <Topgreen /> */}

      {/*Here creating TopGreen Head */}
      <Container fluid className="testcase-report-nav">
        <Row className="test-row">
          <Col md={5}>
            <div className="d-flex first-head justify-content-start">
              Test Case Execution Report -   <span style={{color: "black"}}>TC- {`${test_id} ${test_name}`}</span> 
              
            </div>
          </Col>


          <Col md={7}>
            <div className="d-flex justify-content-end report-icons me-4">

              {/*Mail dropdown */}

              {/* {sendMail && <div className="send-Mail">

                <div id="list1" className="dropdown-check-list">
                  <span className="anchor">Select Stakeholders</span>
                  <ul className="items text-start overflow-scroll text-break">
                    <li><input type="checkbox" className="mr-2 ml-2" />Ram@ignitho.com </li>
                    <li><input type="checkbox" className="mr-2 ml-2" />Arun@ignitho.com</li>
                    <li><input type="checkbox" className="mr-2 ml-2" />Yasin@ignitho.com </li>
                  </ul>
                  <img src={send} className="mail-send" alt="send mail icon" />
                </div>
              </div>} */}


              {/*Mail dropdown */}

              {/* <img
                src={Mail}
                className="reportIcon"
                style={{ cursor: "pointer" }}
                alt="mail icon"
                onClick={() => setSendMail(!sendMail)}
              /> */}
              {/* <Pdf targetRef={ref} filename="document.pdf">
                {({ toPdf }) => (
                  <button
                    style={{ border: "none" }}
                    onClick={toPdf}
                    className="button"
                  >
                   
                  </button>
                )}
              </Pdf> */}
              <img
                      src={DownloadReport}
                      className="reportIcon-download"
                      style={{ cursor: "pointer" }}
                      alt="dReport icon"
                      onClick={onButtonClick}
                    />

              <img
                src={Back}
                className="reportIcon-download"
                style={{ cursor: "pointer" }}
                alt="back icon"
                onClick={() => navigate(-1)}
              />
            </div>
          </Col>
        </Row>
      </Container>

      {/*Here starting the Execution summary part*/}

      <div className="Testcase-Execution-main-head row">
        {/* <PDFExport paperSize="A4" margin="1cm" ref={pdfExportComponent}> */}

        <div  ref={ref}
          className={`Execution-sum-head overflow-hidden align-middle ${isDrawOpen && " col-8"
            }`}
          id="report-export">

          {/* Here starting table section*/}
          <div className={`Execution_test_report_head row`}>
            <div className="repo-table-header ml-0">
              <table className="testcase-report-table">
                <thead className="repo-thead">
                  <tr>
                    <th scope="col">Execution Time</th>
                    <th scope="col">Environment</th>
                    <th scope="col">Executed By</th>
                    <th scope="col">Test Duration</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody className="align-middle">
                  {all_cases.map((data,i) => (
                    <tr key={i}  >
                      <td> <span className="ms-2" style={{float: "left",verticalAlign:"center" }}>{data._id === tick && tickMark ? (
                                    <img src={publish} alt="" />
                                  ) : (
                                    ""
                                  )}
                                  {/* {i === 0 && tickMark ? (
                                    <img className="" src={publish} alt="" />
                                  ) : (
                                    ""
                                  )} */}</span>
                                 {moment(data.createdAt).format("D MMM h:mm a")}</td>
                      <td onClick={() => onClick(data,i)} style={{cursor: 'pointer'}}>Dev Environment</td>
                      <td>{data.executed_by.Ad_username}</td>
                      <td>
                        {moment(data.run_duration, "ss").format("HH:mm:ss")}
                      </td>
                      <td
                        style={{
                          color: data.run_status === "passed" ? "green" : "red",
                        }}
                      >
                        {data.run_status}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* </PDFExport> */}
        {/*Here creating the sidebar */}

        <div
          className={`sidebar-main-header ${isDrawOpen ? "col-3" : "report_sidebar-close"
            }`}
        >
         <div>Logs</div>
          <div className="report_sidebar">
          <input type="hidden" value={dataId} id = "uniqueId"/>
            {/* all_log_data */}
            {/* {all_log_data} */}
            {all_log ? (
              <table className="report_sideTable">
                <tbody>
                  {run_log_status.length > 0 ? (
                    run_log_status.map((data, i) => (
                      <tr className="report_sideTr">
                      
                        <td className="report_sideTd td-1 ml-4 align-middle text-center">{1 + i}</td>
                        
                        <td className="report_sideTd ml-3">
                          <div>{data.name}</div>
                          <div>
                            {moment(data.result.duration, "ss").format(
                              "HH:mm:ss"
                            )}
                          </div>
                          <div
                            style={{
                              color:
                                data.result.status === "passed"
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {data.result.status}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      Unable to execute given test case, Please check the
                      gherkin language entered in the Create testcase editor
                    </span>
                  )}
                </tbody>
              </table>
            ) : (
              <table className="report_sideTable">
                <tbody>
                  <tr className="report_sideTr">
                    <td className="report_sideTd">
                      <div>Id:{all_log_data.id}</div>
                      <div>Keyword:{all_log_data.keyword}</div>
                      <div>Name:{all_log_data.name}</div>
                      <div>Line:{all_log_data.line}</div>
                      <div>Type:{all_log_data.type}</div>
                      <div>
                        {/* {moment(data.result.duration, 'ss').format('HH:mm:ss')} */}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          {/*Here creating a div for display the view all logs */}

          {/* <div className="report-logs align-middle">
            <Link className="GeneratedLink" onClick={() => setAllLog(!all_log)}>
              {all_log ? "View All Logs" : "View Run Logs"}
            </Link>
          </div> */}

          {/*Ending the all run logs div */}
        </div>
      </div>
      </div>
     
    </>
  );
}

export default TestcaseReport;
