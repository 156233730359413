import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import edit from "./../../../assets/Icons/Edit.svg";
import Form from "react-bootstrap/Form";
import "./EditTestsuite.css";
import chrome from "./../../../assets/Icons/Chrome.svg";
import safari from "./../../../assets/Icons/Safari.svg";
import firefox from "./../../../assets/Icons/Firefox.svg";
import edge from "./../../../assets/Icons/Edge.svg";
import apple from "./../../../assets/Icons/iOS.svg";
import android from "./../../../assets/Icons/Android.svg";
import { API } from "./../../../global";
import Tippy from "@tippyjs/react";
import Multiselect from "multiselect-react-dropdown";

const options = [
  {
    scroll: false,
    backdrop: false,
    placement: "end",
  },
];

function OffCanvasExample({ ...props }) {

  let editDataValue=props.editts
  const [show, setShow] = useState(false);
 
  const toggleShow = () => setShow((s) => !s);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [environment, setEnvironment] = useState("");
  const [version, setVersion] = useState("");
  const [testcaselist, setTestcaselist] = useState([]);
  // const [selected_testcases, setSelectedTestcases] = useState([])
  // const [searchTS, setSearchTS] = useState('');
  const [projectlist, setProjectlist] = useState([]);
  const [applist, setApplist] = useState([]);
  const [projectId, setProjectId] = useState('');
  const [applicationId, setApplicationId] = useState("");
  const [checkTS, setCheckTS] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  // const [selected_testcases, setSelectedTestcases] = useState([]);
  // const [searchTS, setSearchTS] = useState("");
  // const [unique_ts, setUniqueTs] = useState([])
  // let arrList = [];
  let mailList = [];
  let finalArr = [];
  let ray = [];
// let searchData= []


  useEffect(() => {
    // getTestcases();
     getProjects();
          if(props.editts.application_id !==undefined){
          setApplicationId(props.editts.application_id._id);
          getTestcases(props.editts.application_id._id)
          // getApplication(projectId)
        }
          if(props.editts.project_id !==undefined){
            getApplication(props.editts.project_id._id)
          // getApplication(projectId)
          setProjectId(props.editts.project_id._id)
        }
        setEnvironment(props.editts.environment);
        setVersion(props.editts.version);
        setCheckTS(finalArr)

        if(props.editts.test_suite_id){
          setName(props.editts.test_suite_id.name);
          setDescription(props.editts.test_suite_id.description);
          setVersion(props.editts.test_suite_id.version);
        }else{
          setName(props.editts.name);
          setDescription(props.editts.description);
        }
   
    // eslint-disable-next-line
  }, [props.editts]);
  const handleClose = () =>{ 
    if(props.editts.application_id !==undefined){
      setApplicationId(props.editts.application_id._id);
      // getApplication(projectId)
    }
      if(props.editts.project_id !==undefined){
      // getApplication(projectId)
      setProjectId(props.editts.project_id._id)
    }
    setEnvironment(props.editts.environment);
    setVersion(props.editts.version);
    setCheckTS(finalArr)

    if(props.editts.test_suite_id){
      setName(props.editts.test_suite_id.name);
      setDescription(props.editts.test_suite_id.description);
      setVersion(props.editts.test_suite_id.version);
    }else{
      setName(props.editts.name);
      setDescription(props.editts.description);
    }
    setShow(false)
  };

  // if(editDataValue.test_suite_id){
  //   editDataValue.test_suite_id.test_cases.forEach((d, i) => {
  //     testcaselist.forEach((s, i) => {
  //       if (d === s._id) {
  //         console.log(s,'ssss');
  //         finalArr.push(s) 
  //       }
  //     })
  //   })
  // }else{
  //   editDataValue.test_cases.forEach((d, i) => {
  //     testcaselist.forEach((s, i) => {
  //       if (d._id === s._id) {
  //         finalArr.push(s) 
  //       }
  //     })
  //   })
  // }
// console.log(finalArr,'finar');
  const handleProjectId = (event) => {
    setProjectId(event.target.value);
    getApplication(event.target.value);
    if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {project_id, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,project_id:"Cannot be blank"}
        return changes
       })
     } 
  };
  const handleApplicationId = (event) => {
    setApplicationId(event.target.value);
    getTestcases(event.target.value)
    if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {project_id, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,application_id:"Cannot be blank"}
        return changes
       })
     } 
  };


  const getProjects = async () => {
    const userId= localStorage.getItem('user-id')

    await fetch(`${API}/admin/project`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      //.then((data) => console.log(data.data))
      .then((pro) => {
        // console.log(pro.data);
        let projectData = [...pro.data];
        console.log(projectData,'project');
        let not_empty=[];
        let userList = [];
        let assign_project_list = []
        projectData.forEach((d,i)=>{
        if(d.project_users.length !==0){
          not_empty.push({i:i, ...d})
         }
        })
        not_empty.forEach((a)=>{
           userList.push(a.project_users)
       
        })
         const assign_project = userList.flat().filter(d=>d.user_id===userId);
        projectData.forEach((d)=>{
         if(assign_project.some(a=>a.project_id===d._id)){
           assign_project_list.push(d)
         }
        })
        setProjectlist(assign_project_list);
        // getApplication(props.editts.project_id)
        // pro.data.length > 0 ? setProjectId(pro.data[0]._id) : setProjectId();
      });
    // .then(() => getApplication());
    //setLoading(false);
  };
  const getApplication = async (val) => {
    setApplist([]);
    await fetch(`${API}/admin/application/${val}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((val) => val.json())
      .then((app) => {

        app.data.length > 0 ? setApplist(app.data) : setApplist([]);
        // app.data.length > 0
        //   ? setApplicationId(app.data[0]._id)
        //   : setApplicationId("");
      });
  };


  const getTestcases = async (val) => {
    //setTestcaselist([]);
    if (applicationId !== "") {
      await fetch(`${API}/tests/testcases/application/?application_id=${val}`, {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }) //promise
        .then((value) => value.json())
        // .then((data) => console.log(data.data)) //Response Object
        .then((tcs) => {
          const publish = tcs.data.filter((test)=> test.published)
          publish.forEach(option=>{
            option.displayValue = 'TC'+ option.test_case_id + "\t" + option.test_case_name
         })
          tcs.data.length > 0 ? setTestcaselist(publish) : setTestcaselist([]);
          if(editDataValue.test_suite_id){
            editDataValue.test_suite_id.test_cases.forEach((d, i) => {
              tcs.data.forEach((s, i) => {
                if (d === s._id) {
                  finalArr.push(s) 
                  setCheckTS(finalArr)
                }
              })
            })
          }else{
            editDataValue.test_cases.forEach((d, i) => {
              tcs.data.forEach((s, i) => {
                if (d._id === s._id) {
                  finalArr.push(s) 
                  setCheckTS(finalArr)
                }
              })
            })
          }

          // editDataValue.test_suite_id.test_cases.forEach((d, i) => {
          //   console.log(d,'aaa');
          //       tcs.data.forEach((s, i) => {
          //         if (d === s._id) {
          //           finalArr.push(s) 
          //         }
          //       })
          //       console.log(finalArr,'finalArr');
          //     })
        });
       
      //   searchData.forEach(option=>{
      //     option.displayValue=option._id;
      //  })
      //  console.log(searchData,'yyyyy');
    }
 
  };
 

 
   

  const editTestsuite = () => {
    
    // if (arrList.length !== 0) {
      finalArr = [];     // let stakemem = [...arrList, ...finalArr];
      mailList = [];
      ray = [];
      checkTS.forEach((ar, i) => {
        if (mailList[i] !== ar._id)
          mailList.push(ar._id);
      })
      ray = mailList.filter((l, i, a) => a.indexOf(l) === i);

      const updatedTestsuite = {
        name: name,
        description: description,
        last_run_status: "Pending",
        tenant_id: "04527940-52a6-4f78-9bc4-253f9546b748",
        environment: environment,
        version: version,
        test_cases: ray,
        project_id: projectId,
        application_id: applicationId,
        last_run: Date.now(),
        updated_by: localStorage.getItem("user-id"),
      };

      const validate = () => {

        let errors = {};
        if (!updatedTestsuite.name) {
          errors.name = "Cannot be blank";
        } 
        if (!updatedTestsuite.environment) {
          errors.environment = "Cannot be blank";
        } 
        if (!updatedTestsuite.test_cases) {
          errors.test_cases = "Cannot be blank";
        } 
        if (!updatedTestsuite.description) {
          errors.description = "Cannot be blank";
        } 
        if (!updatedTestsuite.version) {
          errors.version = "Cannot be blank";
        } 
        
        if (!updatedTestsuite.project_id) {
          errors.project_id = "Cannot be blank";
        } 
        if (!updatedTestsuite.application_id) {
          errors.application_id = "Cannot be blank";
        } 
       
        return errors;
      };
      validate()
    // }
    // else {
    //   mailList = [];
    //   ray = [];
    //   let stakemem = [...arrList, ...finalArr];
    //   // let ray = stakemem.filter((l, i, a) => a.indexOf(l) == i);
    //   stakemem.forEach((ar, i) => {
    //     if (mailList[i] !== ar._id)
    //       mailList.push(ar._id);
    //   })
    //   ray = mailList.filter((l, i, a) => a.indexOf(l) === i);
    // }
    // const permittedValues = selected_testcases.map(function (value) {
    //   return value._id;
    // });
    if(Object.keys(validate()).length === 0){
      fetch(`${API}/tests/testsuites/update/${props.editts.test_suite_id !==undefined ? props.editts.test_suite_id._id: props.editts._id }`, {
        method: "PUT",
        body: JSON.stringify(updatedTestsuite),
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }).then(() => {
        handleClose();
        window.location.reload();
      });
    }else{
      setFormErrors(validate());
    }
    
  };
  // const checkList = (e, t, c) => {
  //   let arr = [...selected_testcases];
  //   if (t) {
  //     arr = [...arr, e];
  //   } else if (t === false) {
  //     arr = arr.filter((item) => item._id !== c);
  //   }

  //   let result = arr.reduce((unique, o) => {
  //     if (!unique.some((obj) => obj._id === o._id)) {
  //       unique.push(o);
  //     }
  //     return unique;
  //   }, []);
  //   setSelectedTestcases(result);
  // };
  const handleEnvironment = (event) => {
    setEnvironment(event.target.value);
    if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {environment, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,environment:"Cannot be blank"}
        return changes
       })
     }  
  };
  const handleVersion = (event) => {
    setVersion(event.target.value);
    if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {version, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,version:"Cannot be blank"}
        return changes
       })
     }  
  };
  // const handleRemove = (id) => {
  //   const newList = selected_testcases.filter((item) => item._id !== id);
  //   setSelectedTestcases(newList);
  // };
 

  return (
    <>
      <Tippy placement="top" theme="light" content="Edit" className="tippy-tooltip">
        <img
          src={edit}
          onClick={()=> {
            toggleShow()
            getTestcases(applicationId)
          }}
          style={{ cursor: "pointer" }}
          alt="edit icon"
        />
      </Tippy>

      <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        className="TS-Canvas-edit-head"
      >
        <Offcanvas.Body>
          <Offcanvas.Title className="ts-edit mt-4">
            Edit Test Suite
          </Offcanvas.Title>
          <Form.Group >
            <Form.Label className="edit-label mt-4">
              Select Project
            </Form.Label>
            <Form.Select 
              type="text"
              className="TS-create-form select-ts-env"
              required
              value={projectId}
              onChange={handleProjectId}
            >
              {projectlist.map((data, i) => {
                return (
                  <option
                    // key={i}
                    selected={data._id === projectId ? true : false}
                    value={data._id}
                  >
                    {data.name}
                  </option>
                );
              })}
            </Form.Select>
            {formErrors.project_id && (
            <span className="error">{formErrors.project_id}</span>
          )}
          </Form.Group>
          <Form.Group value={applicationId}>
            <Form.Label className="edit-label mt-4">
              Select Application
            </Form.Label>
            <Form.Select
              type="text"
              className="TS-create-form select-ts-env"
              required
              value={applicationId}
              onChange={handleApplicationId}
            >

              {applist.map((data, i) => (
                <option
                  key={i}
                  selected={data._id === applicationId ? true : false}
                  value={data._id}
                >
                  {data.name}
                </option>
              ))}
            </Form.Select>
            {formErrors.application_id && (
            <span className="error">{formErrors.application_id}</span>
          )}
          </Form.Group>
          <Form.Label htmlFor="basic-url" className="edit-label mt-4">
            Test Suite Name
          </Form.Label>
          <input
            type="text"
            className="ts-form TS-edit-form form-control"
            value={name}
            onChange={(event) =>{
             setName(event.target.value) 
             if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {name, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,name:"Cannot be blank"}
                return changes
               })
             }  
              }}
          
          />
             {formErrors.name && (
            <span className="error">{formErrors.name}</span>
          )}
      
          <Form.Label htmlFor="basic-url" className="edit-label mt-3">
            Test Suite Description
          </Form.Label>
          <Form.Control
            as="textarea"
            className="ts-form TS-edit-form"
            value={description}
            onChange={(event) =>{
              setDescription(event.target.value)
              if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {description, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,description:"Cannot be blank"}
                return changes
               })
             }  
              }}
          />
             {formErrors.description && (
            <span className="error">{formErrors.description}</span>
          )}

          <Form.Group className="mt-3" value={environment}>
            <Form.Label className="edit-label">Select Environment</Form.Label>
            <Form.Select
              className="select-ts-env TS-edit-form"
              value={environment}
              onChange={handleEnvironment}
            >
              <option value="Alpha Testing Environment">
                Alpha Testing Environment
              </option>
              <option value="Dev Environment">Dev Environment</option>
              <option value="Cloud Environment">Cloud Environment</option>
              <option value="Performance Testing Environment">
                Performance Testing Environment
              </option>
              <option value="System Integrating Testing(SIT)">
                System Integrating Testing(SIT)
              </option>
              <option value="User Acceptance Testing(UAT)">
                User Acceptance Testing(UAT)
              </option>
              <option value="Security Testing">Security Testing</option>
            </Form.Select>
            {formErrors.environment && (
            <span className="error">{formErrors.environment}</span>
          )}
          </Form.Group>
          <div className="browser-select d-flex mt-4">
            <div className="">
              <Form.Label htmlFor="basic-url" className="edit-label">
                Select Browser / Mobile OS
              </Form.Label>
              <div className="browser-icon d-flex">
                <img
                  src={chrome}
                  style={{ cursor: "pointer" }}
                  alt="chrome icon"
                />
                <img
                  src={safari}
                  style={{ cursor: "pointer" }}
                  alt="safari icon"
                />
                <img
                  src={firefox}
                  style={{ cursor: "pointer" }}
                  alt="firefox icon"
                />
                <img src={edge} style={{ cursor: "pointer" }} alt="edge icon" />
                <img
                  src={apple}
                  style={{ cursor: "pointer" }}
                  alt="apple icon"
                />
                <img
                  src={android}
                  style={{ cursor: "pointer" }}
                  alt="android icon"
                />
              </div>
            </div>
            <div className="browser-version ms-4">
              <Form.Label
                htmlFor="basic-url"
                className="browser-label"
              // style={{ marginLeft: "15px", fontWeight: "bold" }}
              >
                Select Version
              </Form.Label>

              <Form.Group>
                <Form.Select
                  className="version-select d-flex"
                  value={version}
                  onChange={handleVersion}
                >
                  <option value="Chrome 100">Chrome 100</option>
                  <option value="Chrome 99">Chrome 99</option>
                  <option value="Chrome 98">Chrome 98</option>
                  <option value="Chrome 97">Chrome 97</option>
                  <option value="Chrome 96">Chrome 96</option>
                </Form.Select>
                {formErrors.version && (
            <span className="error">{formErrors.version}</span>
          )}
              </Form.Group>
            </div>
          </div>

          <div className="ts-link mt-3">
            <Form.Label htmlFor="basic-url" className="edit-label">
              Link Test Case
            </Form.Label>
            
            <Multiselect className="EAPP-stake-select"
          displayValue="displayValue"
          
          selectedValues={checkTS}
          
          // onKeyPressFn={function noRefCheck() { }}
           onRemove={function noRefCheck(e) { 
            // arrList = e 
            setCheckTS(e)
            if(e.length === 0){
              setFormErrors(current => {
                const changes =  {...current,test_cases:"Cannot be blank"}
                return changes
               })
             }  
          }}
           avoidHighlightFirstOption= 'false'
          // onSearch={function noRefCheck() { }}
          onSelect={function noRefCheck(e) {
            setCheckTS(e)
            // arrList = e;
            if(e.length !== 0) {
              setFormErrors(current =>{
              const {test_cases, ...rest} = current;
              return rest
              });
             }
            // setSelectedList([...e, ...selectedList])
          }}
          options={[...testcaselist]}
          showCheckbox
        />
        {formErrors.test_cases && (
            <span className="error">{formErrors.test_cases}</span>
          )}
            {/* <div className="mt-2">
              <input
                type="search"
                placeholder="Search"
                className="form-control ts-form TS-edit-form search-bar ms-3"
                required
                onChange={(e) => setSearchTS(e.target.value)}
              />
              <ul
                className="Edit-TS-ul"
                style={{
                  listStyle: "none",
                  width: "94%",
                  height: "100%",
                  overflow: "auto",
                  background: "white",
                  marginLeft: "17px",
                  borderRadius: "5px",
                }}
              >
                {testcaselist.length > 0 ? (
                  testcaselist
                    .filter((tf) => {
                      if (searchTS === "" || searchTS.trim() === "") {
                        return tf;
                      } else if (
                        tf.test_case_name
                          .toLowerCase()
                          .includes(searchTS.toLowerCase())
                      ) {
                        return tf;
                      }
                      return false;
                    })
                    .map((tf, i, array) => (
                      <li key={i}>
                        <input
                          type="checkbox"
                          value={tf._id}
                          onChange={(e) =>
                            checkList(tf, e.target.checked, e.target.value)
                          }
                        />{" "}
                        &nbsp; TC {tf.test_case_id}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{tf.test_case_name}
                      </li>
                    ))
                ) : (
                  <li>Search Testcase</li>
                )}
                <hr />
                {selected_testcases.map((ts, i) => (
                  <li
                    key={i}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    TC{ts.test_case_id}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {ts.test_case_name}
                    <button
                      style={{
                        background: "white",
                        outline: "none",
                        border: "none",
                      }}
                      type="button"
                      onClick={() => handleRemove(ts._id)}
                    >
                      X
                    </button>
                  </li>
                ))}
              </ul>
            </div> */}
            <div className="suite-buttons justify-content-end ml-3">
              <button
                className="save-ts"
                onClick={() => {
                  editTestsuite();
                }}
              >
                Save
              </button>
              <button className="cancel-ts" onClick={()=>{
                handleClose()
                setFormErrors({})
                }}>
                Cancel
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function EditTsButton({ editTest, getTestsuites }) {
  // useEffect(() => { }, [editTest]);
  return (
    <>
      {options.map((props, idx) => (
        <OffCanvasExample
          key={idx}
          {...props}
          editts={editTest}
          testsuites={getTestsuites}
          placement={props.placement}
        />
      ))}
    </>
  );
}
