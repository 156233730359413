import { Table } from "react-bootstrap";
import "./AdminUpcomingTest.css";
import { API } from '../../../../global';
import { useParams } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";

function UpcomingTest() {
  const [scheduleList, setScheduleList] = useState([]);

  const {context} = useParams()
  useEffect(() => {
    getSchedule();
  }, [context])

  const getSchedule = () => {
    fetch(
      `${API}/tests/testsuites/schedule`,
      {
        method: "GET",
        // headers: {
        //   authorization: `${localStorage.getItem("access-key")}`,
        // },
      }
    )
      .then((val) => val.json())
      .then((app) => {
        let scheduledArr = [...app.data];
        let filteredData = [];
        if(localStorage.getItem('app_id')&&localStorage.getItem('suite_id') === null){
          filteredData= scheduledArr.filter(d=>d.testsuite_id.application_id===localStorage.getItem("app_id"));
        }else if(localStorage.getItem('suite_id')){
           filteredData= scheduledArr.filter(d=>d.testsuite_id._id===localStorage.getItem("suite_id"));
        }else{
           filteredData= scheduledArr.filter(d=>d.testsuite_id.project_id===localStorage.getItem("project-id"));
        }
        let time_data = filteredData.sort((a, b) => a.time.localeCompare(b.time));
        let value = time_data.sort((a, b) => a.start_date.localeCompare(b.start_date));
        let arrr= [];
         const summed = value.reduce((acc, cur, i) => {
         const item = i > 0 && acc.find((name) => name.testsuite_id._id === cur.testsuite_id._id && name.start_date === cur.start_date && name.time === cur.time)
      if (item){
      arrr.push(cur.frequency)
      item.frequency = arrr
      }
        else acc.push({...cur});
        return acc;
}, [])
console.log(summed,"frequency")
app.data.length > 0 ? setScheduleList(summed) : setScheduleList([]);
      })
  }

 

  return (
    <>
      <div className="table_content">
        {scheduleList.length > 0 ?<Table className="table-upcomingtest">
          <thead>
            <tr>
              <th scope="col" colSpan={1}>
                Test Suite
              </th>
              <th scope="col" colSpan={1}>
                Schedule Frequency
              </th>
              <th scope="col" colSpan={1}>
                Date & Time
              </th>
            </tr>
          </thead>
          <tbody>
          {scheduleList.map((schedule,i)=>(
            <tr key={i}>
              <td colSpan={1}>{schedule.testsuite_id.name}</td>
              <td colSpan={1}>{
                typeof schedule.frequency === "string" ? 
                 schedule.frequency :
                           schedule.frequency.length === 1 ? schedule.frequency[0] :
                           schedule.frequency.length === 2 ? `${schedule.frequency[0]}, ${schedule.frequency[1]}` :
                           schedule.frequency.length === 3 ? `${schedule.frequency[0]}, ${schedule.frequency[1]}, ${schedule.frequency[2]}`:schedule.frequency }</td>
              <td colSpan={1}>{`${moment(schedule.start_date).format("D MMM")} ${new Date('1970-01-01T' + schedule.time + 'Z')
  .toLocaleTimeString('en-US',
    {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
  )}` }</td>
            </tr>
          ))}
          {
            
 
          }
            {/* <tr>
              <td colSpan={1}>TS102_Verify sign in-athendication</td>
              <td colSpan={1}>Daily</td>
              <td colSpan={1}>22 jul 9.00AM</td>
            </tr> */}
          </tbody>
        </Table>: 'No Data Found'}
      </div>
    </>
  );
}export default UpcomingTest;
