import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "./AdminSummary.css";
import { useEffect, useState } from "react";
import { API } from "../../../../global";
import moment from "moment";

import { useParams } from "react-router";
let slidesToShow = 5;

const PreviousBtn = (props) => {
  // console.log(props);
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {currentSlide !== 0 && (
        <div className={className} onClick={onClick}>
          <span style={{ color: "#805382", fontSize: "30px" }}>
            <i className="bi bi-chevron-left"></i>
          </span>
        </div>
      )}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  // console.log(props);
  return (
    <>
      {currentSlide !== slideCount - slidesToShow && (
        <div className={className} onClick={onClick}>
          <span style={{ color: "#805382", fontSize: "30px" }} className="nextArr">
            <i className="bi bi-chevron-right"></i>
          </span>
        </div>
      )}
    </>
  );
};
ChartJS.register(ArcElement, Tooltip, Legend);



const carouselProperties = {
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  slidesToShow: slidesToShow,
  slidesToScroll: 2,
  infinite: false,
  // slidesToScroll={3}
  responsive: [
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        centerMode: false,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        centerMode: false,
        slidesToScroll: 2,
      },
    },
  ],
};

function AdminSummary() {
  const [width, setWidth] = useState(window.innerWidth);
  const [testsuiteData, setTestsuiteData] = useState([]);
  // const [testcaseData, setTestcaseData] = useState({});
  const { context } = useParams();
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };
  const project_id = localStorage.getItem("project-id");
  const application_id = localStorage.getItem("app_id");

  useEffect(() => {
    getTestsuiteLogs();
    getTestcaseLogs();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
    // eslint-disable-next-line
  }, [context]);

  const getTestsuiteLogs = () => {
    setTestsuiteData([])
    if(localStorage.getItem("suite_id")!=='null' && localStorage.getItem("suite_id")!==null){
      fetch(`${API}/tests/testsuites/report/runlog/dashboard/${localStorage.getItem("suite_id")}` ,{
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }).then((data) => data.json())
      .then((val)=>{
        console.log(val,"suite1");
        setTestsuiteData(val.data)
      } )
    }else{
    fetch(project_id && application_id === null ? `${API}/tests/testsuite/testlog/?project_id=${project_id}` : `${API}/tests/testsuite/testlog/?project_id=${project_id}&&application_id=${application_id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }).then((data) => data.json())
      .then((val) => {
        console.log(val,'vall');
        setTestsuiteData(val.data)
      })
    }
  }
  const getTestcaseLogs = () => {
    fetch(project_id && application_id === null ? `${API}/tests/testsuite/summary/?project_id=${project_id}` : `${API}/tests/testsuite/summary/?project_id=${project_id}&&application_id=${application_id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }).then((data) => data.json())
      .then((val) => {
        console.log("testcase", val)
        // setTestcaseData(val)
      })
  }

  if (width <= 426) {
    slidesToShow = 1;
  } else if (width > 426 && width <= 769) {
    slidesToShow = 3;
  } else if (width > 769 && width <= 1025) {
    slidesToShow = 4;
  } else {
    slidesToShow = 5;
  }

  return (
    <>
      <div
        className="carousel Summary-carousel"
      >
        <div className="summary_header">
          <span>Recent Test Execution Summary</span>
        </div>

        {testsuiteData.length !== 0 ? <Slider {...carouselProperties}>
          {
            testsuiteData.map((d,i)=>(
              <div className="summary">
            <div className="summary_chart">
              <Doughnut
                className="dChart h-3 w-3 mb-2"
                data={ {datasets: [
                  {
                    data: [d.passed_test_cases.length, d.failed_test_cases.length, d.skipped_test_cases.length, (d.passed_test_cases.length+d.failed_test_cases.length+ d.skipped_test_cases.length)-d.testcase_count],
                    backgroundColor: ["#7EB965", "#DC3545", "#CECECE", "#808080"],
                    hoverBackgroundColor: ["#7EB965", "#DC3545", "#CECECE", "#808080"],
                    borderWidth: 0,
                  },
                ]}}

                plugins={[
                  {
                    id: "here comes your id for the specific plugin",
                    beforeDraw: function (chart) {
                      var width = chart.width,
                        height = chart.height,
                        ctx = chart.ctx;
                
                      ctx.restore();
                      var fontSize = (height / 120).toFixed(2);
                      ctx.font = "bold " + fontSize + "em sans-serif";
                      ctx.textBaseline = "top";
                      var text = `${(d.passed_test_cases.length/d.testcase_count)*100} %`,
                        textX = Math.round((width - ctx.measureText(text).width) / 2),
                        textY = height / 2;
                      ctx.fillStyle = "#555555";
                      ctx.fillText(text, textX, textY);
                      ctx.save();
                    },
                  }]
                }
                options={{
                  cutout: 45,
                  responsive: true,
                  maintainAspectRatio: true,
                }}
              />
            </div>
            <div className="summary_list">
              <ul>
                <>
                  <li>Test Suite ID</li>
                  <li>TS{d.testsuite_id + 100}</li>
                  <li>Last Executed</li>
                  {/* <li>{d.updated_at}</li> */}
                  <li>{moment(d.updated_at).format("D MMM h:mm a")}</li>
                  <li>Test Case Count</li>
                  <li>{d.passed_test_cases.length}/{d.testcase_count}</li>
                  {/* <li>{`(${testsuiteData.passedCaseCount}/${testsuiteData.totalCase}) Passed`}</li> */}
                </>
              </ul>
            </div>
          </div>
            ))
          }
        </Slider>
          : <div className="exe-report">No Testsuites were executed recently.</div>}
      </div>
    </>
  );
}

export default AdminSummary;
