import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { GoPlus } from "react-icons/go";
import "tippy.js/themes/light.css";
import { API } from "../../../../global";
import "./CreateProject.css";
import { MultiSelect } from "react-multi-select-component";
// import editProject from '../EditProject/EditProject';

const options = [
  {
    scroll: false,
    backdrop: false,
    placement: "end",
  },
];

function OffCanvasExample({ ...props }) {
  const [show, setShow] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectDesc, setProjectDesc] = useState("");
  const [proMembers, setProMembers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);
  const toggleShow = () => setShow((s) => !s);

  const handleClose = () => {
    setProjectName("");
    setProjectDesc("");
    setFormErrors({})
    setBtnDisable(false)
    setShow(false);
  };

  useEffect(() => {
    projectUser()
  },[])

  const newProject = {
    created_by: localStorage.getItem("user-id"),
    updated_by: localStorage.getItem("user-id"),
    name: projectName,
    description: projectDesc,
    user_ids: selectedUser,
    tenant_id: "1",
    department_id: "1",
  };
  const validate = (values) => {
    let errors = {};

    if (!newProject.name) {
      errors.name = "Name should not be blank";
    }
    if (!newProject.description) {
      errors.description = "Description should not be blank";
    }
    if (!newProject.user_ids) {
      errors.user_ids = "Project should be selected";
    }
    return errors;
  };

  const addProject = async () => {
    validate()
    setBtnDisable(true)
 
    if (Object.keys(validate()).length === 0) {
      await fetch(`${API}/admin/project`, {
        method: "POST",
        body: JSON.stringify(newProject),
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }).then(() => {
        handleClose();
        props.getProject();
        // window.location.reload();
      });
    } else {
      setFormErrors(validate());
    }
    setBtnDisable(false)
    setSelectedUser([])

  };

    //Getting user list
  const projectUser = () => {
    fetch(`${API}/users`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }).then((val) => val.json())
      .then((users) => {
        console.log(users, "users")
         setProMembers(users)
      })
  };

  const userOptions = proMembers.map(user => ({
    label: user.Ad_username,
      value: user._id 
  }));

  //Selected project members user ID

  const handleUserId = (userId) => {
    const selectedUserId = userId.map(option => option.value);
    setSelectedUser(selectedUserId);
  }
  
  const overrideStrings = {
    selectSomeItems: "Select the members",
    allItemsAreSelected: "All members are selected",
    selectAll: "Select All",
    search: "Search",
    removeAll: "Remove All",
    noOptions: "No project members",
  };

    return (
      <div>
        <div
          className="create-project-btn"
          onClick={toggleShow}
          style={{ cursor: "pointer" }}
        >
          <span>
            <GoPlus className="add-icon" />
          </span>
          Create Project
        </div>

        <Offcanvas
          className="create-project-canvas"
          show={show}
          onHide={handleClose}
          {...props}
        >
          <Offcanvas.Header>
            <Offcanvas.Title className="create-pj">
              <span>Create Project</span>
              {/* <span
              onClick={handleClose}
              style={{
                marginLeft: "200px",
                cursor: "pointer",
                fontSize: "20px",
                fontWeight: "normal",
              }}
            >
              X
            </span> */}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Form.Label
            className="create-pj-label"
            htmlFor="basic-url"
          >
            Project Name
          </Form.Label>
          <span>
            <Form.Control
              type="text"
              placeholder="Enter an project name"
              className="pj-story-box"
              onChange={(event) => {
                setProjectName(event.target.value)
                if (event.target.value.length !== 0) {
                  setFormErrors(current => {
                    const { name, ...rest } = current;
                    return rest
                  });
                } else if (event.target.value.length === 0) {
                  setFormErrors(current => {
                    const changes = { ...current, name: "Cannot be blank" }
                    return changes
                  })
                }
              }}
          
            />
            {formErrors.name && (
              <span className="error">{formErrors.name}</span>
            )}
          </span>

          <br />
          <Form.Label
            className="create-pj-label"
            htmlFor="basic-url"
          >
            Project Description
          </Form.Label>
          <span>
            <Form.Control
              type="text"
              placeholder="Enter an project description"
              className="pj-story-box"
              onChange={(event) => {
                setProjectDesc(event.target.value)
                if (event.target.value.length !== 0) {
                  setFormErrors(current => {
                    const { description, ...rest } = current;
                    return rest
                  });
                } else if (event.target.value.length === 0) {
                  setFormErrors(current => {
                    const changes = { ...current, description: "Cannot be blank" }
                    return changes
                  })
                }
              }}
          
            />
            {formErrors.description && (
              <span className="error">{formErrors.description}</span>
            )}
          </span>


          <Form.Label
            className="create-pj-label mt-4"
            htmlFor="basic-url"
          >
            Project Members
          </Form.Label>

          <div>
            <MultiSelect className="pj-story-box lh-1"
              options={userOptions}
              value={userOptions.filter(opt => selectedUser.includes(opt.value))}
              labelledBy="Select Project Members"
              overrideStrings={overrideStrings}
              onChange={(selectedOptions) => {
                handleUserId(selectedOptions)
                if (selectedOptions.length !== 0) {
                  console.log(selectedOptions, "Selected ops");
                  setFormErrors(current => {
                    const { selectedMember, ...rest } = current;
                    return rest
                  });
                } else if (selectedOptions.length === 0) {
                  setFormErrors(current => {
                    const changes = {...current, selectedMember:"Check atleast one member"}
                    return changes
                  })
                }
              }}
              // value={proMembers}
            />
            {formErrors.selectedMember && (
              <span className="error">{formErrors.selectedMember}</span>
            )}
          </div>
          

          <span className="project-button">
            <button
              type="button"
              disabled={btnDisable}
              className="create-pj-button"
              onClick={() => addProject()
                // disabled={btnDisable}
              }
            >
              Create
            </button>
            <button
              type="button"
              className="cancel-pj-button"
              onClick={() => handleClose()}
            >
              Cancel
            </button>
          </span>
        </Offcanvas>
      </div>
    );
  }

  export default function CreateProject({getProject}) {
    return (
      <>
        {options.map((props, idx) => (
          <OffCanvasExample key={idx} {...props} placement={props.placement} getProject={getProject} />
        ))}
      </>
    );
  }
