import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { GoPlus } from "react-icons/go";
import "tippy.js/themes/light.css";
import { API } from "../../../../global";
import "./CreateUsers.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const options = [
  {
    scroll: false,
    backdrop: false,
    placement: "end",
  },
];

function OffCanvasExample({ ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  //   const handleShow = () => setShow(true);
  const toggleShow = () => setShow((s) => !s);

  const [projectId, setProjectId] = useState("");
  const [applicationId, setApplicationId] = useState("");
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  // const [projectlist, setProjectlist] = useState([]);
  // const [applist, setApplist] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);

  const project_id = localStorage.getItem('project-id')

  const getProject = async () => {
    const proList = await fetch(`${API}/admin/project`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    });
    const list = await proList.json();
    // setProjectlist(list.data);
    list.data.length > 0 && project_id === null && setProjectId(list.data[0]._id)
  };

  useEffect(() => {
    getProject();
    if (project_id) {
      setProjectId(project_id)
      getApplication(project_id)
    }
    // eslint-disable-next-line 
  }, []);

  const handleProjectId = (event) => {
    setProjectId(event.target.value);
    getApplication(event.target.value);
    if (event.target.value.length !== 0) {
      setFormErrors(current => {
        const { project_id, ...rest } = current;
        return rest
      });
    } else if (event.target.value.length === 0) {
      setFormErrors(current => {
        const changes = { ...current, project_id: "Cannot be blanck" }
        return changes
      })
    }
  };
  const handleApplicationId = (event) => {
    setApplicationId(event.target.value);
    if (event.target.value.length !== 0) {
      setFormErrors(current => {
        const { application_id, ...rest } = current;
        return rest
      });
    } else if (event.target.value.length === 0) {
      setFormErrors(current => {
        const changes = { ...current, application_id: "Cannot be blanck" }
        return changes
      })
    }
  };

  const getApplication = (val) => {
    // setApplist([]);
    fetch(`${API}/admin/application/${val}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((val) => val.json())
      .then((app) => {
        // app.data.length > 0 ? setApplist(app.data) : setApplist([]);
        app.data.length > 0 && setApplicationId(app.data[0]._id)
      });
  };

  const newStakeholder = {
    application_id: applicationId,
    project_id: projectId,
    name: name,
    mail: mail,
  };
  const validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!newStakeholder.name) {
      errors.name = "Cannot be blank";
    } else if (newStakeholder.name.length < 3) {
      errors.name = "Name must be more than 3 characters";
    }

    if (!newStakeholder.project_id) {
      errors.project_id = "Cannot be blank";
    }
    if (!newStakeholder.application_id) {
      errors.type = "Cannot be blank";
    }
    if (!newStakeholder.mail) {
      newStakeholder.mail = "Cannot be blank";
    } else if (!regex.test(newStakeholder.mail)) {
      errors.mail = "Enter valid email";
    }

    return errors;
  };

  const createStakeholder = () => {
    validate();
    setBtnDisable(true)

    console.log(validate(), newStakeholder);

    if (Object.keys(validate()).length === 0) {
      fetch(`${API}/admin/stakeholder`, {
        method: "POST",
        body: JSON.stringify(newStakeholder),
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("access-key")}`,
        },
      })
        .then((data) => data.json())
        .then((stake) => {
          if (stake.success === true) {
            toast.success("Created Successfully")
            handleClose();
            window.location.reload();
          } else {
            toast.error(stake.err)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setFormErrors(validate());
      setBtnDisable(false)
    }
  };

  return (
    <>
      <div
        className="create-sh-btn"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "10px",
          background: "#7eb965",
          cursor: "pointer"
        }}
        onClick={toggleShow}
      >
        <span className="">
          <GoPlus className="add-icon" />
        </span>
        Create User
      </div>
      <Offcanvas
        className="create-sh-canvas user-canvas"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header>
          <Offcanvas.Title className="create-sh">
            Create User
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Form.Label
          className="create-sh-label"
          htmlFor="basic-url"
        >
          Name
        </Form.Label>
        <span>
          <Form.Control
            className="sh-story-box"
            type="text"
            placeholder="Enter user name"
            aria-label="default input example"
            value={name}
            onChange={(event) => {
              setName(event.target.value)
              if (event.target.value.length !== 0) {
                setFormErrors(current => {
                  const { name, ...rest } = current;
                  return rest
                });
              } else if (event.target.value.length === 0) {
                setFormErrors(current => {
                  const changes = { ...current, name: "Cannot be blank" }
                  return changes
                })
              }
            }}
          />
          {formErrors.name && (
            <span className="error">{formErrors.name}</span>
          )}
        </span>
        <br />

        <Form.Label
          className="create-sh-label"
          htmlFor="basic-url"
        >
          Email
        </Form.Label>
        <span>
          <Form.Control
            className="sh-story-box"
            type="mail"
            placeholder="Enter user mail id"
            value={mail}
            onChange={(event) => {
              setMail(event.target.value)
              if (event.target.value.length !== 0) {
                setFormErrors(current => {
                  const { mail, ...rest } = current;
                  return rest
                });
              } else if (event.target.value.length === 0) {
                setFormErrors(current => {
                  const changes = { ...current, mail: "Cannot be blank" }
                  return changes
                })
              }
            }}
          />
          {formErrors.mail && (
            <span className="error">{formErrors.mail}</span>
          )}
        </span>

        <br />


        <Form.Label
          className="create-sh-label"
          htmlFor="basic-url"
        >
          Project
        </Form.Label>
        <span>
          <Form.Select
            className="sh-story-box"
            required
            value={applicationId}
            onChange={handleApplicationId}
          >

            <option value="" disabled>Select Project</option>
            <option value="IQA">IQA</option>
            <option value="IDA">IDA</option>
            <option value="Talentio">Talentio</option>        
            {/* {applist.map((data, i) => (
              <option key={i} value={data._id}
                selected={data._id === applicationId ? true : false}
              >
                {data.name}
              </option>
            ))} */}
          </Form.Select>
          {formErrors.application_id && (
            <span className="error">{formErrors.application_id}</span>
          )}
        </span>

        <br />

        <Form.Label
          className="create-sh-label"
          htmlFor="basic-url"
        >
          Roles
        </Form.Label>
        <span>
          <Form.Select
            className="sh-story-box"
            required
            value={projectId}
            onChange={handleProjectId}
          >
            <option value="" disabled>Select Roles</option>
            <option value="Quality Engineer">Quality Engineer</option>
            <option value="Project Manager">Project Manager</option>
            <option value="DevOps">DevOps</option>
            <option value="Admin">Admin</option>
            {/* {projectlist.map((data, i) => (

              <option key={i} value={data._id}
                selected={data._id === projectId ? true : false}
              >
                {data.name}
              </option>
            ))} */}
          </Form.Select>
          {formErrors.project_id && (
            <span className="error">{formErrors.project_id}</span>
          )}
        </span>

        <br />


        <br />







        <span className="create-stake-button">
          <button
            type="button"
            className="create-sh-button"
            disabled={btnDisable}
            onClick={() => createStakeholder()}
          >
            Create
          </button>
          <button type="button" className="cancel-sh-button" onClick={() => {
            handleClose();
            setName("")
            setMail("")
            setFormErrors({})
          }}>
            Cancel
          </button>
        </span>

      </Offcanvas>
    </>
  );
}

export default function CreateUsers() {
  return (
    <>
      {options.map((props, idx) => (
        <OffCanvasExample key={idx} {...props} placement={props.placement} />
      ))}
    </>
  );
}
