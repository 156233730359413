import React, { useEffect, useState } from 'react';
import Navbar from '.././Layout/Navbar/Navbar';
import { Col, Container, Row, Table } from "react-bootstrap";
//import { Oval } from "react-loader-spinner";
//import { useNavigate } from "react-router-dom";
// import { GoPlus } from "react-icons/go";
import "./Schedule.css";
import Tippy from "@tippyjs/react";
import filter from "./../../assets/Icons/Filter.svg";
import execute from "./../../assets/Icons/ExecuteNow.svg";
//import edit from "./../../assets/Icons/Edit.svg";
import publish from "./../../assets/Icons/published.svg";
//import unpublish from "./../../assets/Icons/unpublished.svg";
import EditSchedule from './EditSchedule/EditSchedule';
import CreateSchedule from "./CreateSchedule/CreateSchedule"
import { API } from '../../global';
import { toast } from "react-toastify";


// import Paginate from '../DashboardComponents/Paginate/Paginate';
import DeleteSchedule from './DeleteSchedule/DeleteSchedule';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

function Schedule() {

  const [scheduleList, setSchedule] = useState([]);
  const [schedule_id, setScheduleId] = useState('');
  const [expand, setExpand] = useState(false);
  const [scheduleStake, setScheduleStake] = useState([]);
  const [applist, setApplist] = useState([]);
  const [app_selected, setApp_selected] = useState("");
  const [dropSearchlist, setDropSearchlist] = useState([]);
  const [searchApp, setSearchApp] = useState("");
  const [currentRun, setcurrentRun] = useState("");
  const [currentRunStatus, setcurrentRunStatus] = useState("");
  const project_id = localStorage.getItem("project-id");
  const [searchList, setSearchlist] = useState([]);

  // const applicationId = localStorage.getItem('app_id')
  const app_name = localStorage.getItem("app-id");

  useEffect(() => {
    getScheduleList();
    getStakeholder();
    getApplication()
  }, [project_id])

  const getApplication = () => {

    setApplist([]);
    fetch(
      `${API}/admin/application/${localStorage.getItem("project-id")}`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    )
      .then((val) => val.json())
      .then((app) => {
        let newData = [...app.data];
        newData.unshift({ name: 'All' })
    
    newData.sort((a, b) => {
      if (a.name === 'All') {
        return -1;
      }
      if (b.name === 'All') {
        return 1;
      }
      return a.name.localeCompare(b.name)
    })
        app.data.length > 0 ? setDropSearchlist(newData) : setDropSearchlist([]);
        app.data.length > 0 ? setApplist(newData) : setApplist([]);       
          })
  };
  
  //  const navigate = useNavigate();
  const getScheduleList = (a) => {
    fetch(
      `${API}/tests/testsuites/schedule`,
      {
        method: "GET",
        // headers: {
        //   authorization: `${localStorage.getItem("access-key")}`,
        // },
      }
    )
      .then((val) => val.json())
      .then((app) => {
        let scheduleArr = [...app.data];
       
        if(localStorage.getItem('app_id')){
          const filteredData= scheduleArr.filter(d=>d.testsuite_id.application_id===localStorage.getItem("app_id"));
          filteredData.length > 0 ? setSchedule(filteredData) : setSchedule([]);
          filteredData.length > 0 ? setSearchlist(filteredData) : setSearchlist([]);
        }else{
          const filteredData= scheduleArr.filter(d=>d.testsuite_id.project_id===localStorage.getItem("project-id"));
          filteredData.length > 0 ? setSearchlist(filteredData) : setSchedule([]);
          filteredData.length > 0 ? setSearchlist(filteredData) : setSearchlist([]);
        }
        if(localStorage.getItem("app-id")==='All'){
          const filteredData= scheduleArr.filter(d=>d.testsuite_id.project_id===localStorage.getItem("project-id"));
          filteredData.length > 0 ? setSchedule(filteredData) : setSchedule([]);
          filteredData.length > 0 ? setSearchlist(filteredData) : setSearchlist([]);
        }
        setScheduleId(app.data[app.data.length - 1].schedule_id);
        a==='reload' && window.location.reload();

      })
  }
  const handleDropChange =(e)=> {
    setSearchApp(e);
    console.log(e);
    if(e.length === 0 ){
      setApplist(dropSearchlist)
    }else{
      let fillteredApp= dropSearchlist.filter(d=>d.name.toLowerCase().includes(e.toLowerCase()));
      setApplist(fillteredApp)
    }
    
  }
  const getFilter =(value)=> {
    // console.log(value, 'vlaue');
        let addTstoIds = searchList.map((d)=>{
          return( {test_suite_name : d.testsuite_id.name, created:d.created_by.Ad_username,updated:d.updated_by.Ad_username, ...d})
        });   
        const lowercasedValue = value.toLowerCase().trim();
      
        if (lowercasedValue.length === 0){
          setSchedule(searchList)
        } else {
          const filteredData = addTstoIds.filter(item => {
            return Object.keys(item).some(key =>
               item[key].toString().toLowerCase().includes(lowercasedValue)
            );
          });
          // let removeTstoIds = filteredData.map((d)=>{
          //   return {...d, testsuite_id : d.testsuite_id.replace('TS', '')-100}
          // });
          // console.log(removeTstoIds, 'remove');
    
          setSchedule(filteredData);
        }
      } 
      const run_status = async (id,list_id) => {
        setcurrentRun(list_id)
        await fetch(`${API}/tests/testsuites/run/${id}`, {
          method: "POST",
          body: JSON.stringify(),
          headers: {
            "content-type": "application/json",
            authorization: `${localStorage.getItem("access-key")}`,
          },
        })
          .then(() => toast.success("Execution Started Successfully"))
          .then(() => Interval(id));
    
        // props.status(data.data, new_date);
      };
      
  let t;
  const Interval = (id) => {

    t = setInterval(() => {
      // const test_id = localStorage.getItem("testsuite-id")
      // const app_id = localStorage.getItem("app_id")
      // let load = false;
      clearReload(id)
      getScheduleList()
      // props.getTestsuites(app_id,load);
    }, 3000);
  };
  const clearReload = (id) => {
    fetch(`${API}/tests/testsuites/${id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }) //promise
      .then((value) => value.json()) //Response Object
      .then((tcs) => {
        setcurrentRunStatus(tcs.data.last_run_status)
        if(tcs.data.last_run_status === "passed" || tcs.data.last_run_status === "failed" || tcs.data.last_run_status === "skipped" ){
   clearInterval(t);
        }
      })//skipped
  };
  const getStakeholder = () => {
    // setLoading(true)
    fetch(`${API}/admin/stakeholder`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      .then((pro) => {
        // setScheduleStake(pro.data)

        setScheduleStake(pro.data);
        // setSelectedValue(stakes);
        console.log(pro.data, "stakes schedule");
      })
    // .then(() => setLoading(false))
  };

  return (
    <>
      <div className="">
        <Navbar testcaselist={getFilter}/>

        {/*Topgreen */}

        <Container fluid className="position-fixed SH-head">
          <Row className="SH-row">
            <Col sm={5}>
              <div className="d-flex SH-first-head justify-content-start">
                Schedule List
              </div>
            </Col>

            <Col sm={7}>
              <div className="d-flex justify-content-end SH-test-btns">
                <div className="btn-group">
                  <label className="SH-bulk position-absolute">Action</label>
                  <button
                    type="button"
                    className="SH-bulk-btn me-3 dropdown-toggle"
                    data-bs-toggle="dropdown"
                    size="sm"
                    aria-expanded="false"
                  >
                    Bulk Action
                  </button>
                  <ul className="dropdown-menu">
                    <li className="dropdown-item" style={{ fontSize: "14px" }}>
                      Execute Now
                    </li>
                  </ul>
                </div>

                <div className="btn-group TC-app">
              <label className="TC-App position-absolute">Application</label>
              <div className="TS-bulk-btn me-3">
              <button
                type="button"
                className="TS-app-btn"
                data-bs-toggle="dropdown"
                size="sm"
                aria-expanded="false"
              >
                {app_selected ? app_selected : app_name ? app_name : '- - Select - -'}
                </button>
                                <span className="dropdown-toggle"></span>
              <ul className="dropdown-menu text-center">
              {dropSearchlist.length !== 0 && <li><input type="text" value={searchApp} onChange={(e)=>handleDropChange(e.target.value)}/></li>}

                  {applist.length === 0 ? (
                    <li className="dropdown-item dScroll">No Application found</li>
                     ): (  
                  applist.map((app, i) => {
                  return (
                    <li
                      key={i}
                      className="dropdown-item"
                      onClick={() => {
                        getScheduleList('reload');
                        // getTestcasesByprojectId(project_id,app._id, app.name);
                        setApp_selected(app.name);
                        // testsuitesByAppId(app._id, app.name);
                        localStorage.setItem("app-id", app.name);
                        localStorage.setItem("app_id", app._id)
                      }}
                    >
                      {app.name}
                    </li>
                  );
                  })
  )}

              </ul>
              </div>
              </div>

                <div>
                  <CreateSchedule getSchedule={getApplication} count={schedule_id} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        {/* Table */}

        <div className='SH-table-main'>

          <Table className="SH-table">
            <thead
              className="align-middle TC-thead"
              style={{
                backgroundColor: "#E6C6E840",
                color: "#805382",
              }}
            >
              <tr className="align-middle">
                <th style={{ borderRight: "2px solid #805382", width: "10%" }}>
                  <input type="checkbox" style={{ margin: "6px", zoom: "1" }} />
                  <span onClick={() => setExpand(!expand)}>
                    {!expand ? (
                      <MdArrowDropDown size={15} />
                    ) : (
                      <MdArrowDropUp size={15} />
                    )}
                  </span>
                  S.No
                </th>
                <th style={{ borderRight: "2px solid #805382", width: "13%" }}>
                  Test Suite Name
                </th>
                <th style={{ borderRight: "2px solid #805382", width: "13%" }}>
                  Environment
                </th>
                <th style={{ borderRight: "2px solid #805382", width: "13%" }}>
                  Schedule Frequency
                </th>
                <th style={{ borderRight: "2px solid #805382", width: "10%" }}>
                  Status
                </th>
                <th style={{ borderRight: "2px solid #805382", width: "12%" }}>
                  Created By
                </th>
                <th style={{ borderRight: "2px solid #805382", width: "12%" }}>
                  Updated By
                </th>
                <th style={{ width: "15%" }}>
                  <span style={{ marginLeft: "30px", justifyContent: "center", alignContent: "center" }} className="SH-filter-icon">Actions</span>
                  <img
                    src={filter}
                    className="filter"
                    alt="filter icon"
                  />
                </th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {scheduleList.map((list, i) => (
                <tr style={{ height: "10px" }} className="mt-5">
                  <td>
                    <input type="checkbox" style={{ margin: "5px", zoom: "1" }} />{" "}
                    {i + 1}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    <Tippy
                      placement="top"
                      className="tippy-tooltip"
                      theme="light"

                    >
                      <img
                        src={publish}
                        style={{ marginRight: "10px" }}
                        alt="publish icon"
                      />
                    </Tippy>

                    <Tippy
                      placement="top"
                      className="tippy-tooltip"
                      theme="light"
                      content={list.testsuite_id.name}
                    >
                      <span data-tip="hello world">
                        {list.testsuite_id.name}
                      </span>
                    </Tippy>
                  </td>
                  <td>
                    <Tippy
                      placement="top"
                      className="tippy-tooltip"
                      theme="light"
                      content={list.environment}
                    >
                      <span data-tip="hello world">
                        {list.environment}
                      </span>
                    </Tippy>
                  </td>
                  <td>

                    <span data-tip="hello world">{list.frequency}</span>

                  </td>
                  <td>
                    {currentRun === list._id ? currentRunStatus : list.testsuite_id.last_run_status }
                  </td>

                  <td>
                    {list.created_by.Ad_username}
                  </td>
                  <td>
                    {list.updated_by.Ad_username}
                  </td>
                  <td>
                    <div className="SH-icons">
                      <Tippy
                        placement="top"
                        className="tippy-tooltip"
                        theme="light"
                        content="Execute"
                        
                      >
                        <img
                          src={execute}
                          className="action"
                          style={{ cursor: "pointer" }}
                          alt="execute icon"
                          onClick={()=>run_status(list.testsuite_id._id, list._id)}
                        />
                      </Tippy>

                      <EditSchedule editData={list} getSchedule={getApplication} stakeData={scheduleStake} />
                      <DeleteSchedule deleteId={list._id} getSchedule={getApplication} />
                    </div>
                  </td>
                </tr>
              ))
              }

            </tbody>
          </Table>

        </div>
        {/* <Paginate /> */}
      </div>
    </>
  )
}

export default Schedule