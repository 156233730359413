import "./Topgreen.css";
import { Nav } from "react-bootstrap";
import { useEffect, useState } from "react";
import { API } from "../../../global"
import { useNavigate } from "react-router";
import { Dropdown } from "react-bootstrap";
import { useParams } from "react-router";


function Topgreen() {
  const [applicationlist, setApplicationlist] = useState([]);
  const [testsuiteList, setTestsuite] = useState([]);

  const [app_selected,setApp_selected] = useState("")
  const [suite_selected,setSuite_selected] = useState("")
  const navigate = useNavigate();
  const project_id = localStorage.getItem("project-id")
  const application_id = localStorage.getItem("app_id")
  const app_name = localStorage.getItem("app-id")
  const testsuite_id = localStorage.getItem("suite_id")
  const suite_name = localStorage.getItem("suite_name")
  const [dropSearchlist, setDropSearchlist] = useState([]);
  const [searchApp, setSearchApp] = useState("");
  const [dropSearchlistCase, setDropSearchlistCase] = useState([]);
  const [searchCase, setSearchCase] = useState("");
  const {context} = useParams();

  useEffect(()=>{
    get_project_id()
    getApplication()
    testsuitesByprojectId()
    if (localStorage.getItem("app_id")){

      // testsuitesByAppId()
    }
    // eslint-disable-next-line
  },[project_id])
  useEffect(()=>{
    // get_project_id()
    // getApplication()
    // testsuitesByprojectId()
    // if (localStorage.getItem("app_id")){
    //   alert('localStorage.getItem("app_id")')

      testsuitesByAppId()
    // }
    // eslint-disable-next-line
  },[context])
  
  const get_project_id = () => {
    if(localStorage.getItem('project-id')==='null'){
      navigate(`/qe/dashboard`);
    }
    else if (localStorage.getItem("app_id") !=='null') {
      navigate(`/qe/dashboard`);
    }else{
      navigate(`/qe/dashboard`);
    }
  };

  const getApplication = () => {
    setApp_selected("")
    setApplicationlist([]);
    fetch(
      `${API}/admin/application/${localStorage.getItem("project-id")}`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    )
      .then((val) => val.json())
      .then((app) => {
        let newData=[...app.data];
        let sortData = [{name:'All'},...newData.sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)]
       setApplicationlist(sortData);
       setDropSearchlist(sortData)
      })

  };
  const testsuitesByprojectId = () => {
    // setLoading(true);
    setTestsuite([]);
    fetch(`${API}/tests/testsuite/application/?project_id=${project_id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((val) => val.json())
      .then((test) =>{
        let newData=[...test.data];
        let sortData = [{name:'All'},...newData.sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)]
         setTestsuite(sortData)
         setDropSearchlistCase(sortData)
      }
      )
      // .then(() => setLoading(false));
  };
  const testsuitesByAppId = () => {
    // setLoading(true);
    setTestsuite([]);
   
    fetch(`${API}/tests/testsuite/application/?application_id=${application_id}&project_id=${project_id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((val) => val.json())
      .then((test) =>{
        let newData=[...test.data];
        let sortData = [{name:'All'},...newData.sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)]
         setTestsuite(sortData)
         setDropSearchlistCase(sortData)

      }
      )
      // .then(() => setLoading(false));
    // }
  };
  // const handleApplication = (event) =>{
  //   localStorage.setItem("app_id",event.target.value);
  //   const name = applicationlist.filter((val)=> val._id === event.target.value)
  //   localStorage.setItem("app-id",name[0].name)
  //   navigate(`/qe/dashboard/pr/${event.target.value}`);
  // }
  const handleDropChangeApp =(e)=> {
    setSearchApp(e);
    console.log(e);
    if(e.length === 0 ){
      setApplicationlist(dropSearchlist)
    }else{
      let fillteredApp= dropSearchlist.filter(d=>d.name.toLowerCase().includes(e.toLowerCase()));
      setApplicationlist(fillteredApp)
    } 
  }
  const handleDropChangeCase =(e)=> {
    setSearchCase(e);
    console.log(e);
    if(e.length === 0 ){
      setTestsuite(dropSearchlistCase)
    }else{
      let fillteredApp= dropSearchlistCase.filter(d=>d.test_case_name.toLowerCase().includes(e.toLowerCase()));
      setTestsuite(fillteredApp)
    } 
  }

  const handleSelect = (a,e)=>{
   localStorage.removeItem("suite_id")
    localStorage.removeItem("suite_name")
    if(a===null){
      localStorage.setItem("app-id",'All')
      localStorage.setItem("app_id",null)
      navigate(`/qe/dashboard`);

    }else{
      localStorage.setItem("app_id",a);
      const name = applicationlist.filter((val)=> val._id === a)
      localStorage.setItem("app-id",name[0].name)
      setApp_selected(name[0].name)
      navigate(`/qe/dashboard/pr/${a}`);
    }
  }
  const handleSuiteSelect = (a,e)=>{
    if(a===null){
      localStorage.setItem("suite_name",'All')
      localStorage.setItem("suite_id",null)
      setSuite_selected('All')

      navigate(`/qe/dashboard`);
    }else{
      localStorage.setItem("suite_id",a);
      const name = testsuiteList.filter((val)=> val._id === a)
      localStorage.setItem("suite_name",name[0].name)
      setSuite_selected(name[0].name)
      navigate(`/qe/dashboard/pr/${a}`);
    }
  }
  return (
    <nav className="navbar QE-top-green-navbar navbar-light position-fixed">
      <span
        className="QE-navbar-brand"
      // style={{
      //   color: "#7eb965",
      //   font: "normal normal bold 16px/22px Montserrat",
      //   marginLeft: "40px",
      // }}
      >
        Dashboard
      </span>
      <div className="d-flex justify-content-end dashboard-drop">
        <Nav.Link>
          <label className="DHB-period position-absolute">Period</label>
          <Dropdown>
           
            <Dropdown.Toggle id="dropdown-basic" className="dept-admin me-2 disabled">
              All Time
            </Dropdown.Toggle>

            {/* 
            <Dropdown.Menu className="tg-nv-drop-menu dScroll">
              <Dropdown.Item value="This month" className="dh-drop">This month</Dropdown.Item>
              <Dropdown.Item value="Last 7 days" className="dh-drop">Last 7 days</Dropdown.Item>
              <Dropdown.Item value="Last 30 days" className="dh-drop">Last 30 days</Dropdown.Item>
              <Dropdown.Item value="Last 90 days" className="dh-drop">Last 90 days</Dropdown.Item>
              <Dropdown.Item value="Lifetime" className="dh-drop">Lifetime</Dropdown.Item>
              <Dropdown.Item value="Custom" className="dh-drop">Custom</Dropdown.Item>
            </Dropdown.Menu>*/}
          </Dropdown>
          {/* <select
            className="form-select form-select-sm QE-s-option"
            style={{
              width: "180px",
              height: "32px",
              borderRadius: "5px",
              border: "1px solid #CCCCCC ",
            }}
          >
            <option value="This month">This month</option>
            <option value="Last 7 days">Last 7 days</option>
            <option value="Last 30 days">Last 30 days</option>
            <option value="Last 90 days">Last 90 days</option>
            <option value="Lifetime">Lifetime</option>
            <option value="Custom">Custom</option>
          </select> */}
        </Nav.Link>
        
        <Nav.Link>
          <label className="DHB-app position-absolute">Application</label>
          <Dropdown onSelect={handleSelect}>
            <div className="QE-TG-ts">
            <Dropdown.Toggle id="dropdown-basic" className="QE-s-option me-2" style={{
              background: "#7EB965",
              marginRight: "0px",
              color: "#FFFFFF",
              width: "180px",
              height: "32px",
            }}>
          {app_name ? app_name : app_selected ? app_selected : "- - Select - -"}
            </Dropdown.Toggle>
            </div>
            <Dropdown.Menu className="nv-drop-menu text-center" >
            {dropSearchlist.length !== 0 && <Dropdown.Item><input type="text" value={searchApp} onChange={(e)=>handleDropChangeApp(e.target.value)}/></Dropdown.Item>}

              {applicationlist.length === 1 ? (
                <Dropdown.Item className="dropdown-item dScroll" disabled>No Application found</Dropdown.Item>
              ) : (
                
                  applicationlist.map((app, i) => (
                    <Dropdown.Item value={app._id}
                      className="dh-drop"
                      eventKey={app._id}
                      selected={app._id === application_id ? true : false}
                      key={i}>
                      {app.name}
                    </Dropdown.Item>
                  ))
                )}
            </Dropdown.Menu>
          </Dropdown>
      
        </Nav.Link>


        <Nav.Link>
          <label className="DHB-app position-absolute ms-2">Test Suite</label>
          <Dropdown onSelect={handleSuiteSelect}>
            <div className="QE-TG-ts">
            <Dropdown.Toggle id="dropdown-basic" className="QE-s-option me-2" style={{
              background: "#7EB965",
              marginRight: "0px",
              color: "#FFFFFF",
              width: "180px",
              height: "32px",
            }}>
          {suite_name ? suite_name : suite_selected ? suite_selected : "- - Select - -"}
            </Dropdown.Toggle>
            </div>
            <Dropdown.Menu className="nv-drop-menu text-center" >
            {dropSearchlistCase.length !== 0 && <Dropdown.Item><input type="text" value={searchCase} onChange={(e)=>handleDropChangeCase(e.target.value)}/></Dropdown.Item>}

              {testsuiteList.length === 1 ? (
                <Dropdown.Item className="dropdown-item dScroll" disabled>No Test Suite found</Dropdown.Item>
              ) : (
                
                  testsuiteList.map((app, i) => (
                    <Dropdown.Item value={app._id}
                      className="dh-drop"
                      eventKey={app._id}
                      selected={app._id === testsuite_id ? true : false}
                      key={i}>
                      {app.name}
                    </Dropdown.Item>
                  ))
                )}
            </Dropdown.Menu>
          </Dropdown>
          {/* <select
            className="form-select form-select-sm QE-s-option"
            aria-label=".form-select-sm example"
            style={{
              backgroundColor: "#7EB965",
              marginRight: "0px",
              color: "#FFFFFF",
              width: "180px",
              height: "32px",
            }}
          >
            <option disabled>Select the Test Suite</option>
            <option value="1">Test Suite 1</option>
            <option value="2">Test Suite 2</option>
            <option value="3">Test Suite 3</option>
            <option value="3">Test Suite 4</option>
            <option value="3">Test Suite 5</option>
          </select> */}
        </Nav.Link>
      </div>
    </nav>
  );
}

export default Topgreen;
