import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import edit from "../../../../assets/Icons/Edit.svg";
import Form from "react-bootstrap/Form";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import "./EditProject.css";
import { useEffect } from "react";
import { API } from "../../../../global";
import { MultiSelect } from "react-multi-select-component";


const options = [
  {
    scroll: false,
    backdrop: false,
    placement: "end",
  },
];

function OffCanvasExample({ ...props }) {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow((s) => !s);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");
  // const [projectName, setProjectName] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);
  const [proMembers, setProMembers] = useState([]);
  const [existingUser, setExistingUser] = useState([]);
  const [tenant_id, setTenantId] = useState("");

  let editUsers = props.editValue;
  console.log(editUsers, 'uuu');
  const handleClose = () => {
    setShow(false);
    setTenantId(props.editValue.tenant_id);
    setName(props.editValue.name);
    setDescription(props.editValue.description);
    setCreatedBy(props.editValue.created_by._id);
    setUpdatedBy(props.editValue.updated_by);
    setFormErrors({})
  };

  let checkedUser = [];
  let proUserList = [];
  let objUsers = [];
  // let proId = localStorage.getItem('project-id'); 

  useEffect(() => {

    if (props.editValue.created_by !== undefined) { 
      setCreatedBy(props.editValue.created_by._id);
    }
    // getProject();
    projectUser();
    setTenantId(props.editValue.tenant_id);
   setName(props.editValue.name);
    console.log(props, 'props val');
    setDescription(props.editValue.description);
    setType(props.editValue.type);

    setUpdatedBy(localStorage.getItem("user-id"),);
// eslint-disable-next-line
  }, [props.editValue]);

  const userOptions = proMembers.map(user => ({
      
    label: user.Ad_username,
      value: user.label 
    }));
  


  const unselectedUsers = userOptions.filter(user =>
  {
    const isSelected = existingUser.some(existing => existing._id === user.value);
    return !isSelected;
  });


  const allUsers = unselectedUsers;
  console.log(unselectedUsers, 'Alllll') 
  
  const projectUser = () => {
    checkedUser = []
    fetch(`${API}/users`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }).then((val) => val.json())
      .then((users) => {
        console.log("Fetched users:", users);
        const checkedUsers = users.map((user) => ({
        label: user._id,
          Ad_username: user.Ad_username,
          displayValue: user.Ad_username,
        }));
        // users.length > 0 ? setProMembers(checkedUsers) :
        console.log("Modified users:", checkedUsers);
        setProMembers(checkedUsers);

    if (users && users.length > 0) {

      const user_ids = editUsers.project_users.map(projectUser => projectUser.user_id);

      checkedUser = users.filter(user => user_ids.includes(user._id));
      let objUsers = [];
      checkedUser.map((item) => {
        const objSelectUser = { label: item.Ad_username, value: item._id }
        objUsers.push(objSelectUser)
        return objSelectUser
      })
      setExistingUser(objUsers);
      console.log("objjj", objUsers);
      console.log(checkedUser, 'existingUser')
    } else {
      console.error("No users data received from the server.");
    }
        console.log("Existing userssss:", existingUser);
      }
      )
};

  const editProject = () => {
    console.log(existingUser, 'Exist User');
    checkedUser = [];
    proUserList = [];
    objUsers = [];

   
    existingUser.forEach((ur, i) => {
      if (proUserList[i] !== ur._id)
        proUserList.push(ur._id);
    })
    objUsers = proUserList.filter((l, i, a) => a.indexOf(l) === i);
    console.log(objUsers, 'listing');
    
  const EditData = {
    tenant_id: tenant_id,
    name: name,
    description: description,
    type: type,
    user_ids: existingUser.map((d) =>{return d.value}),
    created_by: createdBy,
    updated_by: updatedBy,
  };

    const validate = () => {
     let errors = {};

      if (!EditData.name) {
        errors.name = "Cannot be blank";
      }
      if (!EditData.description) {
        errors.description = "Cannot be blank";
      }
      if (!EditData.user_ids.lenght === 0) {
        errors.user_ids = "Cannot be uncheck"
      }
      return errors;
    };
    validate();
    setBtnDisable(true);
 
    if (Object.keys(validate()).length === 0) {
      fetch(`${API}/admin/project/${props.editValue._id}`, {
        method: "PUT",
        body: JSON.stringify(EditData),
        headers: {
          "content-type": "application/json",

          authorization: `${localStorage.getItem("access-key")}`,
        },
      }).then(() => {
        // projectLst()
        handleClose();
        window.location.reload();
        // localStorage.removeItem("ts-edit");
      });
    } else {
      setFormErrors(validate());
      // setBtnDisable(false)
    }
  };

  //Multi select options

  const overrideStrings = {
    selectSomeItems: "Select the members",
    allItemsAreSelected: "All members are selected",
    selectAll: "Select All",
    search: "Search",
    removeAll: "Remove All",
    noOptions: "No project members",
  };
  
  return (
    <div>
      <Tippy
        placement="top"
        className="tippy-tooltip"
        theme="light"
        content="Edit"
      >
        <img
          src={edit}
          onClick={toggleShow}
          className="action"
          style={{ cursor: "pointer" }}
          alt="edit icon"
        />
      </Tippy>

      <Offcanvas
        className="edit-project-canvas"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header>
          <Offcanvas.Title className="edit-pj">
            <span>Edit Project</span>
            {/* <span
              onClick={handleClose}
              style={{
                marginLeft: "200px",
                cursor: "pointer",
                fontSize: "20px",
                fontWeight: "normal",
              }}
            >
              X
            </span> */}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Form.Label
          className="edit-pj-label"
          htmlFor="basic-url"
        >
          Project Name
        </Form.Label>
        <span className="edit-project">
          <Form.Control
            value={name} 
            type="text"
            placeholder="Enter a project name"
            className="ed-story-box"
            onChange={(event) => {
              setName(event.target.value)
              if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {name, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,name:"Name should not be blank"}
                return changes
               })
             }  
            }}
          />
        
          {formErrors.name && (
              <span className="error">{formErrors.name}</span>
            )}
        </span>

        <br />
        <Form.Label
          className="edit-pj-label"
          htmlFor="basic-url"
        >
          Project Description
        </Form.Label>
        <span className="edit-project">
          <Form.Control
            type="text"
            placeholder="Enter a project description"
            className="ed-story-box"
            onChange={(event) => {
              setDescription(event.target.value)
              if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {description, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,description:"Description should not be blank"}
                return changes
               })
             }  
            }}
            value={description}
          />
          {formErrors.description && (
            <span className="error">{formErrors.description}</span>
          )}
        </span>

         <Form.Label
            className="create-pj-label mt-4"
            htmlFor="basic-url"
          >
            Project Members
          </Form.Label>

          <div>
          <MultiSelect className="pj-story-box lh-1"
            displayValue='displayValue'
           
            value={existingUser}
              // value={userOptions.filter(opt => selectedUser.includes(opt.value))}
              labelledBy="Select Project Members"
            overrideStrings={overrideStrings}
            
            
            onRemove={function noRefCheck(e) {
              setExistingUser(e)

              if (e.length === 0) {
                setFormErrors(current => {
                  const changes = { ...current, user_ids: "Atleast select one member" }
                  return changes
                })
              }
            }
            }


              onChange={function noRefCheck(e) {
                console.log(e,'eeee')
                setExistingUser(e)
                if (e.length !== 0) {
                  setFormErrors(current => {
                    const { user_ids, ...rest } = current;
                    return rest
                  });
                } 
            }}
            options={allUsers}
           
              showCheckbox
            /> 
            {formErrors.user_ids && (
              <span className="error">{formErrors.user_ids}</span>
            )}
        </div>
        
        <span className="edit-project-button">
          <button
            type="button"
            disabled={btnDisable}
            className="edit-pj-button"
            onClick={() => editProject()}
          >
            Save
          </button>
          <button
            type="button"
            className="cancel-pj-edit"
            onClick={() => handleClose()}
          >
            Cancel
          </button>
        </span>
      </Offcanvas>
    </div>
  );
}

export default function EditProject({ editApp, getProject }) {
  useEffect(() => { }, [editApp]);
  return (
    <>
      {options.map((props, idx) => (
        <OffCanvasExample
          key={idx}
          {...props}
          placement={props.placement}
          editValue={editApp}
          getProject={getProject}
        />
      ))}
    </>
  );
}