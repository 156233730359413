import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import edit from "../../../../assets/Icons/Edit.svg";
import Form from "react-bootstrap/Form";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import "./EditStakeholder.css";
import { useEffect } from "react";
import { API } from "../../../../global";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const options = [
  {
    scroll: false,
    backdrop: false,
    placement: "end",
  },
];

function OffCanvasExample({ ...props }) {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow((s) => !s);
  

  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  // const [project,setProject] = useState("")
  const [projectId, setProjectId] = useState("");
  const [applicationId, setApplicationId] = useState("");
  const [projectlist, setprojectlist] = useState([]);
  const [applicationlist, setApplicationlist] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  // const [btnDisable, setBtnDisable] = useState(false);
  // const [createdBy, setCreatedBy] = useState("");
  // const [updatedBy, setUpdatedBy] = useState("");
  // const proId = localStorage.getItem('project-id')
  const handleClose = () => {
    setShow(false)
    setName(props.editValue.name);
    setMail(props.editValue.mail); 
    setFormErrors({})

  };
  useEffect(() => {
    getProject();
    console.log("holder", props.editValue);
    if(props.editValue.project_id !== undefined){
      setApplicationId(props.editValue.application_id._id)
      getApplication(props.editValue.project_id._id);
      setProjectId(props.editValue.project_id._id);
    }
    setName(props.editValue.name);
    setMail(props.editValue.mail);
    // eslint-disable-next-line
  }, [props.editValue]);

 

  const getProject = async () => {
     await fetch(`${API}/admin/project`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
    .then((val) => val.json())
      .then((app) => {
        setprojectlist(app.data)
        console.log(app.data,'data');
        // app.data.length > 0
        //   ? setApplicationId(app.data[0]._id)
        //   : setApplicationId("");
      });
    // const list = await appList.json();
    // setprojectlist(list.data);
    // console.log(projectlist);
  };
  const EditData = {
    tenant_id: "123",
    name: name,
    mail:mail,
    project_id: projectId,
    application_id : applicationId
  };
  const validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!EditData.name) {
      errors.name = "Cannot be blank";
    }else if (EditData.name.length < 3) {
      errors.name = "Name must be more than 3 characters";
    }

    if (!EditData.project_id) {
      errors.project_id = "Cannot be blank";
    } 
    if (!EditData.application_id) {
      errors.type = "Cannot be blank";
    } 
    if (!EditData.mail) {
      errors.mail = "Cannot be blank";
    } else if (!regex.test(EditData.mail)) {
      errors.mail = "Invalid email format";
    }
   
    return errors;
  };
  const getApplication =  (id) => {
    setApplicationlist([]);
     fetch(`${API}/admin/application/${id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((val) => val.json())
      .then((app) => {
         setApplicationlist(app.data)
        // app.data.length > 0
        //   ? setApplicationId(app.data[0]._id)
        //   : setApplicationId("");
      });
  };
  
  const EditStakeholder = () => {
    validate();
    // setBtnDisable(true)
    if(Object.keys(validate()).length === 0){
    fetch(`${API}/admin/stakeholder/${props.editValue._id}`, {
      method: "PUT",
      body: JSON.stringify(EditData),
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
    .then((data)=> data.json())
    .then((stake) => { 
      if (stake.success === true) { 
        toast.success("Edited Successfully")
        handleClose();
        window.location.reload();
      }else{
        toast.error(stake.err)
      }    
   
      })
      .catch((err) => {
        console.log(err);
      });
    }else{
      setFormErrors(validate());
  
    }
  };

  return (
    <div>
      <Tippy
        placement="top"
        className="tippy-tooltip"
        theme="light"
        content="Edit"
      >
        <img
          src={edit}
          onClick={toggleShow}
          className="action"
          style={{ cursor: "pointer" }}
          alt="edit icon"
        />
      </Tippy>

      <Offcanvas
        className="edit-sh-canvas"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header>
          <Offcanvas.Title className="edit-sh">
            <span>Edit StakeHolder</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Form.Label
          className="edit-sh-label"
          htmlFor="basic-url"
        >
          Project Name
        </Form.Label>
        <span>
          <Form.Select
            className="ed-sh-story-box"
            onChange={(event) =>
               {setProjectId(event.target.value)
                getApplication(event.target.value);
              }
              }
            value={projectId}
          >
            {projectlist.map((data) => {
              return (
                <option value={data._id} className="opt">
                  {data.name}
                </option>
              );
            })}
          </Form.Select>
          {formErrors.project_id && (
            <span className="error">{formErrors.project_id}</span>
          )}</span>

          <br/>
          <Form.Label
          className="edit-sh-label"
          htmlFor="basic-url"
        >
          Application Name
        </Form.Label>
        <span>
          <Form.Select
             className="ed-sh-story-box"
            onChange={(event) => setApplicationId(event.target.value)}
            value={applicationId}
          >
            {applicationlist.map((data) => {
              return (
                <option
                selected={data._id === applicationId ? true : false}
                 value={data._id} className="opt">
                  {data.name}
                </option>
              );
            })}
          </Form.Select>
          {formErrors.application_id && (
            <span className="error">{formErrors.application_id}</span>
          )}
        </span>
          <br />

          <Form.Label
          className="edit-sh-label"
          htmlFor="basic-url"
        >
          StakeHolder Name
        </Form.Label>
        <span>
          <Form.Control
           className="ed-sh-story-box"
            type="text"
            value={name}
            onChange={(event)=> setName(event.target.value)}
          />
          {formErrors.name && (
            <span className="error">{formErrors.name}</span>
          )}
          </span>
          <br />
          <Form.Label
          className="edit-sh-label"
          htmlFor="basic-url"
        >
          StakeHolder Mail ID
        </Form.Label>
        <span>
          <Form.Control
         className="ed-sh-story-box"
            type="mail"
            value={mail}
            onChange={(event)=> setMail(event.target.value)}
          />
          {formErrors.mail && (
            <span className="error">{formErrors.mail}</span>
          )}
</span>
          <span className="edit-stake-button">
            <button type="button" 
            onClick={()=>EditStakeholder()} className="ed-create-sh-button">
              Save
            </button>
            <button type="button" className="ed-cancel-sh-button" onClick={handleClose}>
              Cancel
            </button>
          </span>
      </Offcanvas>
    </div>
  );
}

export default function EditStakeholder({ editholder }) {
  console.log("edit", editholder);
  useEffect(() => {}, [editholder]);
  return (
    <>
      {options.map((props, idx) => (
        <OffCanvasExample
          key={idx}
          {...props}
          placement={props.placement}
          editValue={editholder}
        />
      ))}
    </>
  );
}
