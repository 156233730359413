import React, { useEffect, useState } from "react";
import Header from "../../Layout/Navbar/Navbar";
import "./CreateTestcase.css";
// import { GoPlus } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { SelectionText } from "@uiw/react-textarea-code-editor";
import Editor from "@monaco-editor/react";
import "react-toastify/dist/ReactToastify.css";
import { API } from "../../../global";
import { toast } from "react-toastify";
import { Col, Container, Row } from "react-bootstrap";
// import execute from './../../../assets/Icons/run-icon.svg';
// import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-gherkin";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";


export default function CreateTestcase() {
  const textRef = React.useRef();
  const [code, setCode] = React.useState("");
  // const editorRef = useRef(null);
  const [savebtn, setSavebtn] = useState(false);

  // function handleEditorChange(value, event) {
  //   setCode(value);
  // }
  const [userstory, setUserstory] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("Functional");
  // const [applist, setApplist] = useState([]);
  const [description, setDescription] = useState("");
  const [eresult, setEresult] = useState("");
  // const [projectlist, setProjectlist] = useState([]);
  const [applist, setApplist] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [applicationId, setApplicationId] = useState(localStorage.getItem("app_id") ? localStorage.getItem("app_id") : '');
  const [formErrors, setFormErrors] = useState({});
  const testcaseId = localStorage.getItem("testcase-id");
  // const [app_selected, setApp_selected] = useState("");
  // const [appid, setAppid] = useState("");
  const [projectlist, setProjectlist] = useState([]);
  // const [prodrop, setProdrop] = useState("");
  // const testcasesByAppId = (id) => {
  //   setAppid(id);
  // };

  const handleTestcaseType = (event) => {
    setType(event.target.value);
    if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {test_case_type, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,test_case_type:"Cannot be blank"}
        return changes
       })
     }  
  };
  const handleProjectId = (event) => {
    setProjectId(event.target.value);
    getApplication(event.target.value);
    if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {project_id, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,project_id:"Cannot be blank"}
        return changes
       })
     } 
  };
  const handleApplicationId = (event) => {
    setApplicationId(event.target.value);
    if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {application_id, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0 || event.target.value===undefined){
      setFormErrors(current => {
        const changes =  {...current,application_id:"Cannot be blank"}
        return changes
       })
     } 
  };

  const getApplication = (val) => {
    setApplist([]);
    fetch(`${API}/admin/application/${val}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((val) => val.json())
      .then((app) => {
        app.data.length > 0 ? setApplist(app.data) : setApplist([]);
        app.data.length > 0 && appId === undefined && setApplicationId(app.data[0]._id);
      });
  };
  const appId = localStorage.getItem("app_id")
  const proId = localStorage.getItem('project-id')

  useEffect(() => {
    if (textRef.current) {
      const obj = new SelectionText(textRef.current);
      console.log(obj);
    }

    getProjects();
    if (proId) {
      setProjectId(proId)
      getApplication(proId);
    }
    if (appId) {
      setApplicationId(appId)
    }
    // eslint-disable-next-line
  }, []);



  const getProjects = async () => {
    await fetch(`${API}/admin/project`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      //.then((data) => console.log(data.data))
      .then((pro) => {
        setProjectlist(pro.data);
        // pro.data.length > 0 ? setProjectId(pro.data[0]._id) : setProjectId();
      })
    // .then(() => getApplication());
    //setLoading(false);
  };

  const navigate = useNavigate();

  const newTestcase = {
    created_by: localStorage.getItem("user-id"),
    updated_by: localStorage.getItem("user-id"),
    user_story: userstory,
    test_case_type: type,
    test_case_name: name,
    test_case_description: description,
    last_run_status: "Pending",
    expected_result: eresult,
    test_steps: code,
    project_id: projectId,
    application_id: applicationId,
    test_case_id: testcaseId,
  };
  const validate = () => {
    let errors = {};
    if (!newTestcase.user_story) {
      errors.user_story = "Cannot be blank";
    } 
    if (!newTestcase.test_case_type) {
      errors.test_case_type = "Cannot be blank";
    } 
    if (!newTestcase.test_case_name) {
      errors.test_case_name = "Cannot be blank";
    } 
    if (!newTestcase.test_case_description) {
      errors.test_case_description = "Cannot be blank";
    } 
    if (!newTestcase.expected_result) {
      errors.expected_result = "Cannot be blank";
    } 
    if (!newTestcase.test_steps) {
      errors.test_steps = "Cannot be blank";
    } 
    if (!newTestcase.project_id) {
      errors.project_id = "Cannot be blank";
    } 
    if (!newTestcase.application_id) {
      errors.application_id = "Cannot be blank";
    } 
    if (!newTestcase.test_case_id) {
      errors.test_case_id = "Cannot be blank";
    } 
    return errors;
  };

  const project_val = localStorage.getItem("project_id");

  const addTestcase = (a) => {
    validate()
    console.log(newTestcase);
    // let publish_testcase =
    //   a === "0" ? { ...newTestcase } : { ...newTestcase, published: true };
    if(Object.keys(validate()).length === 0) {
      let publish_testcase =
        a === "0" ? { ...newTestcase } : { ...newTestcase, published: true };
      
        setSavebtn(!savebtn);
        fetch(`${API}/tests/testcases`, {
          method: "POST",
          body: JSON.stringify(publish_testcase),
          headers: {
            "content-type": "application/json",
            authorization: `${localStorage.getItem("access-key")}`,
          },
        }).then(() => {
          createRevision(a);
          navigate(`/qe/testcase/pr/${project_val}`);
          a === "0"
            ? toast.success("Saved as Draft Successfully")
            : toast.success("Published Successfully");
        });
    }else{
      setFormErrors(validate());
    }
    
  };
  
  const createRevision = (a) => {
    if (a === "1") {
      fetch(`${API}/tests/testcases/revision`, {
        method: "POST",
        body: JSON.stringify({
          test_case_id: newTestcase.test_case_id,
          test_steps: newTestcase.test_steps,
          updated_by: newTestcase.updated_by,
        }),
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("access-key")}`,
        },
      });
    }
  };

  return (
    <> <Header />
      <div className="testcase-create">

        <div>
          <Container fluid>
            <Row className="createtest-test-row">
              <Col sm={5}>
                <div className="d-flex first-head justify-content-start">
                  Test Cases
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container fluid className="create_test_Cfluid">
            <Row className="createtst_main">
              <Col sm={4}>
                <div className="TC-crt-sec-head d-flex justify-content-start">
                  Create Test Case
                </div>
              </Col>
              <Col>
            {/* <div className="d-flex justify-content-end me-3"><button className="clt-run-button">
              <img
                src={execute}
                style={{ color: "white", backgroundColor: "none",width:"19px" }}
                alt="run icon"
                className="me-2"
              />
           
            Run</button>
            </div> */}
            
            </Col>
            </Row>
          </Container>
        </div>

        <Container fluid >
          <Row className="mt-4 overflow-hidden">
            <Col sm={4}>
              <div className="justify-content-center ms-5 text-start">
                <Form className="TC-crt-user-story-menus">
                  <Form.Group>
                    <Form.Label className="mb-1">Select Project</Form.Label>
                    <Form.Select
                      type="text"
                      className="create-form form-control mb-3"
                      required
                      value={projectId}
                      onChange={handleProjectId}
                    >
                      {projectlist.map((data, i) => (
                        <option
                          selected={data._id === projectId ? true : false}
                          value={data._id}
                        >
                          {data.name}
                        </option>
                      ))}
                    </Form.Select>
                    {formErrors.project_id && (
            <span className="error">{formErrors.project_id}</span>
          )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mb-1">Select Application</Form.Label>
                    <Form.Select
                      type="text"
                      placeholder="Select Application"
                      className="create-form mb-3"
                      required
                      value={applicationId}
                      onChange={handleApplicationId}
                    >
                      {applist.length === 0 && <option>No Application found</option>}
                      <option value={''}>Select Application</option>
                      {applist.map((data, i) => (
                        <option
                          key={i}
                         selected={data._id === applicationId ? true : false}
                          value={data._id}
                        >
                          {data.name}
                        </option>
                      ))}
                    </Form.Select>
                    {formErrors.application_id && (
            <span className="error">{formErrors.application_id}</span>
          )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mb-1">User Story</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Link User Story"
                      className="create-form mb-3"
                      onChange={(event) =>{
                        setUserstory(event.target.value)
                   
              if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {user_story, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,user_story:"Cannot be blank"}
                return changes
               })
             }   
                      } }
                    />
                  </Form.Group>
                  {formErrors.user_story && (
            <span className="error">{formErrors.user_story}</span>
          )}

                  <Form.Group>
                    <Form.Label className="mb-1">Test Case Type</Form.Label>
                    <Form.Select
                      type="text"
                      placeholder="Select Test Case Type"
                      className="create-form mb-3"
                      required
                      value={type}
                      onChange={handleTestcaseType}
                    >
                      <option value="Functional">Functional</option>
                      <option value="System">System</option>
                      <option value="Performance">Performance</option>
                      <option value="UI">UI</option>
                      <option value="Backend">Backend</option>
                    </Form.Select>
                    {formErrors.test_case_type && (
            <span className="error CTC">{formErrors.test_case_type}</span>
          )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="mb-1">Test Case Name</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      className="create-form mb-3"
                      placeholder="Enter Test Case Name"
                      onChange={(event) =>{
                        setName(event.target.value)
                        if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {test_case_name, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,test_case_name:"Cannot be blank"}
                return changes
               })
             }   
                      } }
                    />
                     {formErrors.test_case_name && (
            <span className="error">{formErrors.test_case_name}</span>
          )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="mb-1">
                      Test Case Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      required
                      className="create-form mb-3"
                      placeholder="Enter Test Case Description"
                      style={{
                        border: "1px #9F9F9F solid",
                        borderRadius: "5px",
                        height: "30px"
                      }}
                      onChange={(event) =>{
                        setDescription(event.target.value)
                        if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {test_case_description, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,test_case_description:"Cannot be blank"}
                return changes
               })
             } 
                      } }
                    />
                     {formErrors.test_case_description && (
            <span className="error">{formErrors.test_case_description}</span>
          )}
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="mb-1">Expected Result</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      className="create-form mb-3"
                      placeholder="Enter Expected Result"
                      style={{
                        border: "1px #9F9F9F solid",
                        borderRadius: "5px",
                      }}
                      onChange={(event) =>{
                        setEresult(event.target.value)
                        if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {expected_result, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,expected_result:"Cannot be blank"}
                return changes
               })
             } 
                      }}
                    />
                     {formErrors.expected_result && (
            <span className="error">{formErrors.expected_result}</span>
          )}
                  </Form.Group>
                </Form>
              </div>
            </Col>
            <Col sm={8}>
              <Col className="text-start crt-editor-head mb-1">Editor</Col>
              <div className="edit-clone">
                <div className="editor-code d-flex justify-content-end">
                  <Editor
                    className="crt-react-editors"
                    value={code}
                    defaultValue="WRITE YOUR TEST CASE"
                    language="javascript"
                    onChange={value =>{
                      setCode(value)
                      if(value.length !== 0) {
      setFormErrors(current => {
    const {test_steps, ...rest} = current;
    return rest
       });
     }else if(value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,test_steps:"Cannot be blank"}
        return changes
       })
     } 
                    } }
                    highlight={code => highlight(code, languages.gherkin).split('\n')
                    .map(
                      (line, index) => `<span><span style=' color:grey'>${index + 1}</span><span>${line}</span></span>`
                    )
                    .join('\n')}
                    style={{
                      overflow: "auto",
                      maxHeight: 500,
                    }}
                    padding={1}
                   
                    WRITE YOUR TESECASE HERE
                  />

                  
                  {/* 
                  <Editor
      height="90vh"
      defaultLanguage="javascript"
      defaultValue="// some comment"
      onChange={value =>{
                      setCode(value)
                      if(value.length !== 0) {
      setFormErrors(current => {
    const {test_steps, ...rest} = current;
    return rest
       });
     }else if(value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,test_steps:"Cannot be blank"}
        return changes
       })
     } 
                    } }
    /> */}
                  {/* <Editor
                  className="crt-react-editors p-2 me-3"
                  value={code}
                  required
                  defaultLanguage="gherkin"
                  defaultValue={code}
                  onChange={handleEditorChange}
                  variant="transparent"
                /> */}
                 {formErrors.test_steps && (
            <span className="TC-error position-absolute bottom-0 end-3">{formErrors.test_steps}</span>
          )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid className="mt-4">
          <Row>
            <Col>
              <div className="Editor-bottom-btns d-flex justify-content-end me-3 mb-3">
                <button
                  // variant="normal"
                  // size="sm"
                  className="me-3 edit-save-as"
                  onClick={() => addTestcase("0")}
                  disabled={applist.length === 0 || (applicationId === "" && projectId === "") ? true : false}
                >
                  Save As Draft
                </button>
                <button
                  // variant="normal"
                  // size="sm"
                  type="submit"
                  className="me-3 edit-publish"
                  onClick={() => {
                    addTestcase("1");
                  }}
                  disabled={applist.length === 0 || (applicationId === "" && projectId === "") ? true : false}
                >
                  Publish
                </button>
                <button
                  // variant="normal"
                  // size="sm"
                  type="cancel"
                  className="edit-cancel"
                  onClick={() => navigate(`/qe/testcase/pr/${project_val}`)}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
