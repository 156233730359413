import React from "react";
import "./Sidebar.css";
import logo from "./../../../assets/Icons/ignitho-logo.svg";
import dashboard from "./../../../assets/Icons/Dashboard.svg";
import test from "./../../../assets/Icons/Test.svg";
import repo from "./../../../assets/Icons/Report-menu.svg";
import schedule from "./../../../assets/Icons/Schedule.svg";
import testsuite from "./../../../assets/Icons/Test Suite.svg";
import testcase from "./../../../assets/Icons/Test Case.svg";
import objectrepo from "./../../../assets/Icons/Object Repo.svg";
import backrepo from "./../../../assets/Icons/Background Repo.svg";
import "tippy.js/themes/light.css";
import { Link } from "react-router-dom";

export default function Sidebar() {
  // const project_id = localStorage.getItem("project-id");
  return (
    <div className="sidebar">
      <div className="d-flex justify-content-center">
          <img
            src={logo}
            className="mt-2 img-fluid"
            alt="ignitho logo"       
          />    
      </div>

      <div className="side-nav close">
        <ul className="nav-links mt-3">
          
          <Link className="link_names" to="/qe/dashboard">
          <li className="nav-img">        
            <img src={dashboard} alt="dashboard-icon" />         
            <ul className="sub-menu">
              <li>   
                  <span className="link_name" style={{ paddingLeft: "20px", margin: "20px" }}>DASHBOARD</span>
              </li>
            </ul>
            </li>
            </Link>

          <li className="nav-img">
            <img src={test} alt="" />
            <ul className="sub-menu">
              <li className="test-case text-center justify-content-center" style={{ color: "white", background: "#5D3B5E" }}>
                <span style={{ margin: "20px" }}>TEST</span>
              </li>
              <li className="test-case">
                {/* { project_id ? <Link to={`/qe/testsuite/pr/${project_id}`}>
                  <img src={testsuite} alt="testcase-icon" />
                  <span>TEST SUITE</span>
                </Link> : <Link to={`/qe/testsuite/pr/124345`}>
                  <img src={testsuite} alt="testcase-icon" />
                  <span>TEST SUITE</span>
                </Link> } */}
                <Link to="/qe/testsuite">
                  <img src={testsuite} alt="testcase-icon" />
                  <span>TEST SUITE</span>
                </Link>
              </li>
              <li className="test-case">
                <Link to="/qe/testcase">
                  <img src={testcase} alt="testcase-icon" />
                  <span>TEST CASE</span>
                </Link>
              </li>
            </ul>
          </li>

          <li className="nav-img">
            <img src={repo} alt="" />
            <ul className="sub-menu">
              <li className="test-case text-center justify-content-center" style={{ color: "white", background: "#5D3B5E" }}>
                <span style={{ margin: "20px" }}>REPORTS</span>
              </li>
              <li className="test-case">
                <Link to="/qe/testsuite/report/table">
                  <img src={objectrepo} alt="" />
                  <span>TESTSUITE REPORT</span>
                </Link>
              </li>

              <li className="test-case text-start">
                <Link to="/qe/testcase/reports">
                  <img src={backrepo} alt="" />
                  <span>
                    TESTCASE REPORT
                  </span>
                </Link>
              </li>
            </ul>
          </li>

          <Link className="link_names" to="/qe/schedule">
          <li className="nav-img">     
            <img src={schedule} alt="schedule icon" />
            <ul className="sub-menu">
              <li>
                
                  {/* <img src={schedule} alt="" /> */}
                  <span className="link_name" style={{ paddingLeft: "20px", margin: "20px" }}>SCHEDULE EXECUTION</span>
              </li>
            </ul>
          </li>
</Link>
        </ul>
      </div>

      <footer className="IQA-version">
        <h5 className="text-white fw-light fixed-bottom justify-content-center align-middle">V2.0</h5>
      </footer>
    </div>
  );
}